export enum Network {
    Facebook = "Facebook",
    Instagram = "Instagram",
    Twitter = "Twitter"
}

export type NetworkUsername = {
    network: Network,
    userName: string
}

export type EditContactInfoProps = {
    username: string;
    contactInfo: Array<NetworkUsername>;
    profileLoaded: boolean;
}