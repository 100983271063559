import { formatDate } from "../../utils/DateUtil";
import { formatEventString } from "../../utils/EventUtil"
import { OwnerHistoryProps } from "./types";
import { Link as RouterLink } from 'react-router-dom';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import Map from '../map';
import React, { useState } from 'react';
import Button from '@mui/material/Button';
import MapIcon from '@mui/icons-material/Map';

const OwnerHistory: React.FunctionComponent<OwnerHistoryProps> = (props) => {

    const [showMap, setShowMap] = useState(false);

    let kandiOwnerHistoryEntries = null;
    if (props.kandiEvents?.length) {
        kandiOwnerHistoryEntries = props.kandiEvents
            .map(kandiEvent => {
                return (
                    <tr key={kandiEvent.timestamp}>
                        <td><Typography variant="body2">{formatDate(kandiEvent.timestamp)}</Typography></td>
                        <td>
                            <Link component={RouterLink} to={`/u/${kandiEvent.userName}`} >
                                <Typography variant="body2">{kandiEvent.userName}</Typography>
                            </Link>{formatEventString(kandiEvent, false)}
                        </td>
                    </tr>
                )
            });
    }



    let kandiOwnerHistoryTable = null;
    if (kandiOwnerHistoryEntries) {
        kandiOwnerHistoryTable = (
            <div>
                <Typography variant="h5">Kandi History</Typography>
                <Button startIcon={<MapIcon />} onClick={() => setShowMap(!showMap)}>Toggle map view</Button>
                {showMap && <Map kandiEvents={props.kandiEvents} />}
                <table>
                    <thead>
                        <tr>
                            <th><Typography variant="body1">Date</Typography></th>
                            <th><Typography variant="body1">Kandi Event Details</Typography></th>
                        </tr>
                    </thead>
                    <tbody>
                        {kandiOwnerHistoryEntries}
                    </tbody>
                </table>
            </div>
        )
    }

    return kandiOwnerHistoryTable;
}

export default OwnerHistory;