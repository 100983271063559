import { SetProfilePhotoModalProps } from "./types";
import { useEffect, useState } from "react";
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import PhotoIcon from '@mui/icons-material/Photo';
import { Theme, createStyles } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import LinearProgress from '@mui/material/LinearProgress';
import { getRequest, postRequest, putRequest } from '../../../service/RequestAdapter';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

interface MyStyles {
    photoPlaceholder: string;
}
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    photoPlaceholder: {
        width: "100%",
        height: "200px",
        margin: "auto",
        textAlign: "center",
        padding: "60px 0",
        borderColor: "lightslategray",
        borderWidth: "2px",
        borderStyle: "dashed",
        borderRadius: "25px"
    }
  }),
);

const SetProfilePhotoModal: React.FunctionComponent<SetProfilePhotoModalProps> = (props) => {
    const classes = useStyles() as MyStyles;

    const [profilePhotoKey, setProfilePhotoKey] = useState(Date.now());
    const [profilePhoto, setProfilePhoto] = useState(undefined as any);
    const [profilePhotoLocalURL, setProfilePhotoLocalURL] = useState("");
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);

    const reset = function() {
        setProfilePhoto(null);
        setProfilePhotoLocalURL("");
        setProfilePhotoKey(Date.now()); // Clears input field
        setIsSubmitting(false);
    }

    const resetAndClose = function () {
        reset();
        props.onClose();
    }

    const handlePhotoChanged = function(e: any) {
        const file = (e.target as any).files?.[0];
        setProfilePhoto(file);
        setProfilePhotoLocalURL(URL.createObjectURL(file))
    }

    const uploadProfilePhoto = async function() {
        console.log("createProfilePhoto called");
        if (profilePhoto) {
            setIsSubmitting(true);

            // Get presigned URL and upload photo to S3
            const uploadPhotoData: any = await postRequest(`users/${props.username}/photoURL`);
            const response = await fetch(uploadPhotoData.presignedURL, {
                method: "PUT", 
                body: profilePhoto,
                headers: {
                    "Cache-Control": "max-age=600" // 600s = 10minutes
                }
            });
            console.log("Photo upload response: " + JSON.stringify(response));

            // Set new photo as default profile picture
            await putRequest(`users/${props.username}/profile/picture`, { photoId: uploadPhotoData.photoId});

            props.onSetProfilePhotoId(uploadPhotoData.photoId);
            await new Promise(r => setTimeout(r, 5000)); // short sleep for eventual consistency

            setShowSuccess(true);
            resetAndClose();
        } else {
            console.warn("CreateProfilePhoto called but profilePhoto is not set.");
        }
    }

    const photoPreview = profilePhotoLocalURL ? (
        <Grid item container direction="column" alignItems="center" >
            <Grid item>
                <img className="photoPreview" src={profilePhotoLocalURL}/>
            </Grid>
        </Grid>
    ) : (
        <Grid item>
            <div className={classes.photoPlaceholder}>
                <PhotoIcon style={{ fontSize: 80 }} />
            </div>
        </Grid>
    );

    const progressComponent = isSubmitting ? (
        <Grid item>
            <LinearProgress />
        </Grid>
    ) : (
        null
    );

    const submitEnabled = profilePhoto && !isSubmitting;

    return (
        <div>
            <Dialog
                open={props.open}
                onClose={props.onClose}
                aria-labelledby="form-dialog-title"
                fullWidth
                maxWidth="xs"
            >
                <DialogTitle id="form-dialog-title">Set Profile Photo</DialogTitle>
                <DialogContent>
                    <Grid 
                        container
                        spacing={3}
                        direction="column"
                    >
                        <Grid item>
                            <Button
                                variant="contained"
                                component="label"
                                color="primary"
                                key={profilePhotoKey}
                                startIcon={<PhotoCamera/>}
                            >
                                Select Photo
                                <input
                                    type="file"
                                    accept="image/*"
                                    hidden
                                    onChange={handlePhotoChanged}
                                />
                            </Button>
                        </Grid>
                        { photoPreview }
                        { progressComponent }
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button color="primary" onClick={resetAndClose} disabled={isSubmitting}>
                        Cancel
                    </Button>
                    <Button color="primary" onClick={uploadProfilePhoto} disabled={!submitEnabled}>
                        Let's GO!
                    </Button>
                </DialogActions>
            </Dialog>
            <Snackbar open={showSuccess} autoHideDuration={3000} onClose={()=>setShowSuccess(false)}>
                <MuiAlert onClose={()=>setShowSuccess(false)} severity="success">
                    Looking good! Profile Photo has been updated!
                </MuiAlert>
            </Snackbar>
        </div>
    );
}

export default SetProfilePhotoModal;