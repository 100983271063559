import React from "react";
import { SignInModalProps } from "./types";
import SignIn from "../signIn";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import Button from '@mui/material/Button';

const SignInModal: React.FunctionComponent<SignInModalProps> = (props) => {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Dialog 
            open={props.open} 
            onClose={props.onClose} 
            aria-labelledby="dialog-title"
            fullScreen={fullScreen}
            maxWidth="sm"
            fullWidth
        >
            <DialogContent>
                <SignIn
                    forModal={true}
                    initialState={props.initialState}
                />
            </DialogContent>
            <DialogActions>
                <Button 
                    color="primary" 
                    onClick={props.onClose}
                >
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default SignInModal;