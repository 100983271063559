import { formatDate } from "../../utils/DateUtil";
import { formatEventString } from "../../utils/EventUtil"
import React, { useEffect } from 'react';
import { Theme, createStyles } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { MapProps } from "./types";
import { Wrapper, Status } from "@googlemaps/react-wrapper";

interface MyStyles {
  map: string;
}
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    map: {
      height: "90vh"
    }
  }),
);

const Map: React.FunctionComponent<MapProps> = (props) => {
    const classes = useStyles() as MyStyles;

    let events : Array<any> = props.kandiEvents;

    return (
        <div>
            <p id="status"></p>
            <div id="map" className={classes.map}></div>
            <Wrapper apiKey={`${process.env.REACT_APP_MAPS_API_KEY}`} render={render}>
                <MyMapComponent {...props} />
            </Wrapper>
        </div>
    );
}

const render = (status: Status): any => {
    if (status === Status.LOADING) return <Spinner />;
    if (status === Status.FAILURE) return <ErrorComponent />;
    return null;
};

  function MyMapComponent(props: MapProps) {

    useEffect(() => {
      let map = new google.maps.Map(document.getElementById("map") as HTMLElement, {
        center: { lat: 47.6131229, lng: -122.4121035 },
        zoom: 11,
      });

      if (props.kandiEvents) {
        var lastwindow: any = null;
        var markerLocs: google.maps.LatLng[] = [];
        var bounds = new google.maps.LatLngBounds();
        (props.kandiEvents as Array<any>)
        .filter((event: any) => event?.location)
        .forEach((event: any) => {
            let coords = event.location.split(',');
            let marker = new google.maps.Marker({position: {lat: parseFloat(coords[0]), lng: parseFloat(coords[1])}, map: map});
            bounds.extend(marker.getPosition() as google.maps.LatLng);
            markerLocs.push(marker.getPosition() as google.maps.LatLng);

            const infowindow = new google.maps.InfoWindow({
                content: `<div style="color:black"><b>${formatDate(event.timestamp)}</b><br />${formatEventString(event, true)}</div>`,
                maxWidth: 300,
              });

            marker.addListener("click", () => {
                if (lastwindow as any) (lastwindow as google.maps.InfoWindow).close();
                infowindow.open({
                  anchor: marker,
                  map,
                  shouldFocus: false,
                });
                lastwindow = infowindow;
              });

              var line = new google.maps.Polyline({
                path: markerLocs as google.maps.LatLng[],
                geodesic: true,
                strokeColor: '#FF0000',
                strokeOpacity: 1.0,
                strokeWeight: 2
              });

              line.setMap(map);
        });
        map.fitBounds(bounds);

        google.maps.event.trigger(map, 'resize');
      }
    });

    return null;
  }

  const Spinner = () => (
    <div>Sit tight, map loading!</div>
  )

  const ErrorComponent = () => (
    <div>Uh oh, unable to load map</div>
  )

export default Map;
