import { useParams } from "react-router-dom";
import { ViewProfileProps } from "./types";
import { publicContentUrl } from '../../config/ServiceConfig';
import useAsyncEffect from 'use-async-effect';
import { getRequest } from "../../service/RequestAdapter";
import { useState } from "react";
import { Theme, createStyles } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import ButtonBase from '@mui/material/ButtonBase';
import { Network, NetworkUsername } from "../../components/editProfile/editContact/types";
import { SocialIcon } from 'react-social-icons';

interface MyStyles {
    profilePhotoPaper: string;
    contactInfoPaper: string;
    profileAvatar: string;
    userName: string;
    socialMediaPaper: string;
    socialMediaUsernameContainer: string;
}
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    profilePhotoPaper: {
        marginTop: theme.spacing(4),
        textAlign: "center",
        maxWidth: theme.breakpoints.values["md"],
        margin: "auto"
    },
    contactInfoPaper: {
        textAlign: "center",
        maxWidth: theme.breakpoints.values["md"],
        padding: theme.spacing(1),
        margin: "auto"
    },
    profileAvatar: {
        maxWidth: "300px",
        maxHeight: "300px",
        width: "75vw",
        height: "75vw"
    },
    userName: {
        wordWrap: "break-word",
        textAlign: "center",
        [theme.breakpoints.up('md')]: {
            maxWidth: "100%",
        },
        [theme.breakpoints.down('sm')]: {
            maxWidth: "90vw",
        },
    },
    socialMediaPaper: {
        padding: theme.spacing(1),
        width: 'fit-content'
    },
    socialMediaUsernameContainer: {
        overflow: "hidden", 
        textOverflow: "ellipsis", 
        width: '11rem'
    }
  }),
);

const NETWORK_BASE_URLS: Record<Network, string> = {
    [Network.Facebook]: "https://www.facebook.com/",
    [Network.Instagram]: "https://www.instagram.com/",
    [Network.Twitter]: "https://twitter.com/",
}

const ViewProfile: React.FunctionComponent<ViewProfileProps> = (props) => {
    const classes = useStyles() as MyStyles;

    let { userName } = useParams() as any;

    const [profilePhotId, setProfilePhotoId] = useState();
    const [contactInfo, setContactInfo] = useState([] as Array<NetworkUsername>);

    useAsyncEffect(async isMounted => {
        const profileData = await getRequest(`users/${userName}/profile`);

        if (!isMounted()) return;
        if (profileData?.profilePhotoId) {
            setProfilePhotoId(profileData.profilePhotoId);
        }
        if (profileData?.contactInfo) {
            setContactInfo(profileData.contactInfo);
        }
    }, []);

    let profilePhotoComp;
    if (profilePhotId) {
        profilePhotoComp = (
            <img 
                src={`${publicContentUrl}users/${userName}/${profilePhotId}`}
            />
        );
    }

    const renderSocialMediaContactInfo = () => {
        const components = contactInfo.map(networkUsername => renderNetwork(networkUsername));
        return (
            <Grid
                item
                container
                direction="row"
                alignItems="center"
                justifyContent="center"
                spacing={2}
             >
                {components.length ? components : (
                    <Grid item>
                         <Typography noWrap variant="body2">
                            This user has not added any contact info yet.
                        </Typography>
                    </Grid>
                )}
            </Grid>
        );
    }

    const renderNetwork = (networkUsername: NetworkUsername) => {
        const url = NETWORK_BASE_URLS[networkUsername.network] + encodeURIComponent(networkUsername.userName);
        return (
            <Grid item key={`${networkUsername.network}-container`}>
                <ButtonBase
                    component={Link}
                    href={url}
                    target="_blank"
                >
                    <Paper
                        className={classes.socialMediaPaper}
                        elevation={24}
                    >
                        <Grid
                            container
                            direction="row"
                            spacing={2}
                            alignItems="center"
                        >
                            <Grid item>
                                <SocialIcon network={networkUsername.network.toLowerCase()} />
                            </Grid>
                            <Grid item>
                                <div className={classes.socialMediaUsernameContainer}> 
                                    <Typography noWrap variant="body2">
                                        <Link>
                                            {networkUsername.userName}
                                        </Link>
                                    </Typography>
                                </div>
                            </Grid>
                        </Grid>
                    </Paper>
                </ButtonBase>
            </Grid>
        );
    }

    return (
        <Grid
            container
            direction="column"
            spacing={3}
        >
            <Grid item>
                <Paper className={classes.profilePhotoPaper}>
                    <Grid
                        container
                        direction="column"
                        alignContent="center"
                        justifyContent="center"
                        spacing={3}
                    >
                        <Grid item>
                            <Avatar
                            className={classes.profileAvatar}
                            src={`${publicContentUrl}users/${userName}/${profilePhotId}`}
                            >
                            </Avatar>
                        </Grid>
                        <Grid item>
                            <Typography variant="h3" className={classes.userName}>{userName}</Typography>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
            <Grid item>
                <Paper className={classes.contactInfoPaper}>
                    <Grid
                        container
                        direction="column"
                        alignContent="flex-start"
                        justifyContent="flex-start"
                        spacing={3}
                    >
                        <Grid item>
                            <Typography variant="h5">
                                Contact Info
                            </Typography>
                        </Grid>
                        {renderSocialMediaContactInfo()}
                    </Grid>
                </Paper>
            </Grid>
        </Grid>
    );
}

export default ViewProfile;