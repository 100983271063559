export async function geoFindMe(callback: Function) {
    console.log("geoFindMe called");
  
    function success(position: GeolocationPosition) {
      const latitude  = position.coords.latitude;
      const longitude = position.coords.longitude;

      console.log("geoFindMe got coords: " + latitude + ", " + longitude);
      callback(position);
    }
  
    function error() {
      console.log('Error retrieving geolocation, or location retrieval denied');
      callback(null);
    }
  
    if (!navigator.geolocation) {
      console.log('Geolocation is not supported by your browser');
      callback(null);
    } else {
      console.log('Locating…');
      navigator.geolocation.getCurrentPosition(success, error);
    }
  }
