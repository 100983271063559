import React, { Dispatch, SetStateAction } from 'react';

interface ContextType {
    firstTimeSignin: boolean,
    clearFirstTimeSignin: () => void,
    enableFirstTimeSignin: () => void
}

const AppContext = React.createContext<ContextType>({ firstTimeSignin:false, 
    clearFirstTimeSignin:() => {}, enableFirstTimeSignin:() => {}});

export default AppContext;