import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import HomeComponent from './pages/home'
import MapComponent from './pages/map'
import NavComponent, { drawerWidth } from './components/nav'
import TagComponent from './pages/tag';
import KandiComponent from './pages/kandi';
import EditProfileComponent from './pages/editProfile';
import ViewUserProfile from './pages/viewUserProfile';
import ContactUs from './pages/contactUs';
import { ThemeProvider, createTheme, responsiveFontSizes, Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import { makeStyles } from "@mui/styles";
import {Helmet} from "react-helmet";
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import { Authenticator } from '@aws-amplify/ui-react';
import AppContextProvider from "./context/AppContextProvider";

/**
 * Material-UI default typography configuration only relies on 300, 400, 500, and 700 font weights.
 * https://material-ui.com/components/typography/#install-with-npm
 */
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";

export const contentMaxWidth = "xl";

interface MyStyles {
  gridContainer: string;
  drawerPlaceholder: string;
  appContainerGridItem: string;
  appContainer: string;
  bottomMargin: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    gridContainer: {
      flexWrap: "nowrap",
      maxWidth: '100vw',
    },
    appContainerGridItem: {
      [theme.breakpoints.up('md')]: {
        width: "80%",
      },
      [theme.breakpoints.down('sm')]: {
        width: "100%",
        alignItems: "center"
      }
    },
    appContainer: {
        height: '100%',
        [theme.breakpoints.up('md')]: {
          maxWidth: `${theme.breakpoints.values[contentMaxWidth]}px`,
        },
        [theme.breakpoints.down('sm')]: {
          maxWidth: '100vw',
        },
        paddingLeft: "0",
        paddingRight: "0"
    },
    drawerPlaceholder: {
      //when menu bar up, add space to the left
      [theme.breakpoints.up('md')]: {
        width: drawerWidth,
        height: '100vh',
      },
      [theme.breakpoints.down('sm')]: {
        display: 'none'
      }
    },
    bottomMargin: {
      height: '75px',
    }
  }),
);



export default function App() {
  const [hideDrawerPlaceholder, setHideDrawerPlaceholder] = useState(false);
  const [mode, setMode] = useState<'light' | 'dark'>('dark');

  const theme = createTheme({
    palette: {
      mode: mode,
      primary: {
        main: "#44D62C;"
      },
      secondary: {
        main: "#DB3EB1;"
      }
    },
    components: {
      MuiFab: {
        styleOverrides: {
          root: {
              position: 'fixed',
              bottom: '70px',
              right: '15px',
              zIndex: 10
          }
        }
      }
    }
  });

  // Include iubenda script for privacy policy, etc.
  useEffect(() => {
    const script = document.createElement('script');
  
    script.src = "https://cdn.iubenda.com/iubenda.js";
    script.async = true;
  
    document.body.appendChild(script);
  
    return () => {
      document.body.removeChild(script);
    }
  }, []);

  const onThemeChangeHandler = () => {
    setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
  }

  const AppComponent = () => {
    const classes = useStyles() as MyStyles;
    return (
      <Authenticator.Provider>
      <Helmet>
        {/** 
         * Helmet will move any child tags to the head of the dom
         * 
         * Material-UI is developed mobile-first, a strategy in which we 
         * first write code for mobile devices, and then scale up components 
         * as necessary using CSS media queries. To ensure proper rendering 
         * and touch zooming for all devices, add the responsive viewport 
         * meta tag to your <head> element.
         * https://material-ui.com/getting-started/usage/
         *  */}
        <meta
          name="viewport"
          content="minimum-scale=1, initial-scale=1, width=device-width"
        />
      </Helmet>
        <Router>
          <Grid container direction="row" className={classes.gridContainer}>
            { hideDrawerPlaceholder ? null : (
              <Grid item>
                <div className={classes.drawerPlaceholder} >
                  &nbsp;
                </div>
              </Grid>
            )}
            <Grid item className={classes.appContainerGridItem}>
              {/* A <Switch> looks through its children <Route>s and
                  renders the first one that matches the current URL. */}
              <Container maxWidth={contentMaxWidth} className={classes.appContainer}>
                <Switch>
                  <Route path="/t/:uuid">
                    <TagComponent />
                  </Route>
                  <Route path="/k/:vanityHandle">
                    <KandiComponent />
                  </Route>
                  <Route path="/u/:userName">
                    <ViewUserProfile />
                  </Route>
                  <Route path="/map">
                    <MapComponent />
                  </Route>
                  <Route path ="/profile">
                    <EditProfileComponent />
                  </Route>
                  <Route path="/contact-us">
                    <ContactUs />
                  </Route>
                  <Route path="/">
                    <HomeComponent setHideDrawerPlaceholder={setHideDrawerPlaceholder} />
                  </Route>
                </Switch>
              </Container>
            </Grid>
          </Grid>
          <div className={classes.bottomMargin}></div>
          <NavComponent onThemeChange={onThemeChangeHandler} colorMode={mode}/>
        </Router>
      </Authenticator.Provider>
    )
  }

  return (
      <ThemeProvider theme={theme}>
        {/**
           * Material-UI provides an optional CssBaseline component. It fixes 
           * some inconsistencies across browsers and devices while providing 
           * slightly more opinionated resets to common HTML elements.
           * https://material-ui.com/getting-started/usage/
           */
        }
        <CssBaseline />
        <AppContextProvider>
          <AppComponent />
        </AppContextProvider>
      </ThemeProvider>
  );
}
