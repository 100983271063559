import React, { useEffect, useState } from 'react';
import { UserKandisProps, Filters } from './types';
import { getRequest } from '../../service/RequestAdapter';
import useAsyncEffect from 'use-async-effect';
import { publicContentUrl } from '../../config/ServiceConfig';
import AddTag from '../addTagModal';
import { Link } from 'react-router-dom';
import { formatDate } from '../../utils/DateUtil';
import Button from '@mui/material/Button';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import ListSubheader from '@mui/material/ListSubheader';
import ButtonBase from '@mui/material/ButtonBase';
import Tooltip from '@mui/material/Tooltip';
import { Theme, createStyles, useTheme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { getWindowDimensions } from '../../utils/DimensionsUtil';
import { drawerWidth, drawerBreakpoint } from '../../components/nav';
import { contentMaxWidth } from '../../App';
import Grid from '@mui/material/Grid';
import FilterListIcon from '@mui/icons-material/FilterList';
import Paper from '@mui/material/Paper';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import FilterDialog, { CurrentlyOwnedFilter } from './filtersDialog';

const imageWidth = 300;

interface MyStyles {
    userKandisContainer: string;
    imageListMultipleColumns: string;
    imageListOneColumnSmall: string;
    titleBar: string;
    icon: string;
    image: string;
    formControl: string;
    tabContainer: string;
}
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    userKandisContainer: {
        width: "fit-content",
        height: "fit-content",
        display: "flex",
        justifyContent: "space-around",
        flexWrap: "wrap",
        paddingTop: theme.spacing(2)
    },
    imageListMultipleColumns: {
        margin: "0",
        justifyContent: "center"
    },
    imageListOneColumnSmall: {
        width: "90%",
        maxWidth: "90%",
        margin: "auto",
        justifyContent: "center"
    },
    titleBar: {
        background:
          'linear-gradient(to bottom, rgba(0,0,0,0.2) 0%, ' +
          'rgba(0,0,0,0.1) 70%, rgba(0,0,0,0) 100%)',
    },
    icon: {
      color: 'rgba(250 ,250, 250, 1)'
    },
    image: {
        objectFit: 'contain',
        width: '100%',
        height: '100%'
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 150,
    },
    tabContainer: {
        width: '100%'
    }
  }),
);

const enum CreatedOrReceivedFilter {
    All = "All",
    Created = "Created",
    Received = "Received"
}

const defaultFilters = {
    currentlyOwnedFilter: CurrentlyOwnedFilter.Current
}


const UserKandis: React.FunctionComponent<UserKandisProps> = (props) => {
    const classes = useStyles() as MyStyles;
    const theme = useTheme();

    const [userKandis, setUserKandis] = useState([] as Array<any>);
    const [selectedUserKandi, setSelectedUserKandi] = useState(null as any);
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
    const [createdOrReceivedFilter, setCreatedOrReceivedFilter] = useState(CreatedOrReceivedFilter.All);
    const [showFilterDialog, setShowFilterDialog] = useState(false);
    const [filters, setFilters] = useState<Filters>(defaultFilters);
    const [lastUpdated, setLastUpdated] = useState(Date.now());

    useAsyncEffect(async isMounted => {
        console.log("callGetUserKandis called");
        const data = await getRequest(`users/${props.userName}/kandis`, {
            createdOrReceivedFilter,
            currentlyOwnedFilter: filters.currentlyOwnedFilter
        });
        console.log ("Success calling getUserKandis asyn effect!");
        if (!isMounted()) return;
        setUserKandis(filterToOneEventPerKandi(data.kandis));
    }, [props.userName, props.lastUpdated, lastUpdated, createdOrReceivedFilter, filters]);

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const filterToOneEventPerKandi = function (kandis: Array<any>) {
        const seenKandis = new Set();
        return kandis.filter((kandi) => {
            if (seenKandis.has(kandi.kandiId)) {
                return false;
            } else {
                seenKandis.add(kandi.kandiId);
                return true;
            }
        })
    }

    const handleCreatedOrReceivedChange = (event: React.ChangeEvent<{}>, newValue: CreatedOrReceivedFilter) => {
        setCreatedOrReceivedFilter(newValue);
    };

    const handleTagSet = (success: boolean) => {
        if (success) {
            //Refresh data to show QR code has been set
            setLastUpdated(Date.now());
        }
        if (props.onSetTag) {
            props.onSetTag(success);
        }
    }

    const renderAddTagButton = function(userKandi: any) {
        if (userKandi.tagId) {
            return (
                null
            );
        } else {
            const tooltipText = props.tag ? 
                `Assign QR code to this Kandi.`
                : "Scan a QR code sticker to assign it to this Kandi so it can be tracked. Note you can also scan it with your device's native camera.";
            return (
                <Tooltip title={tooltipText}>
                    <Button 
                        className={classes.icon} 
                        size="small"
                        onClick={() => {setSelectedUserKandi(userKandi)}}
                    >
                        Add QR
                    </Button>
                </Tooltip>
            );
        }
    }

    const renderKandiCollectionDescription = function() {
        switch(createdOrReceivedFilter) {
            case CreatedOrReceivedFilter.Created:
                return "Kandis you have created."
            case CreatedOrReceivedFilter.Received:
                return "Kandis you have received."
            case CreatedOrReceivedFilter.All:
            default:
                return "Kandis you have created or received."
        }
    }

    const renderKandiDescription = function(userKandi: any) {
        const eventDescription = userKandi.eventType === "KANDI_CREATED" ? "Created" : "Received"
        return (
            <span>{eventDescription} on {formatDate(userKandi.timestamp)}</span>
        )
    }

    //const padding = theme.breakpoints.values["md"] < windowDimensions.width ? (drawerWidth + 150) : 50;
    const widthForDrawer =  theme.breakpoints.values[drawerBreakpoint] < windowDimensions.width ? drawerWidth : 0;
    const windowWidthPlusPadding = Math.min(theme.breakpoints.values[contentMaxWidth] - widthForDrawer, (windowDimensions.width - widthForDrawer));
    const columnCount = Math.max(1, Math.floor(windowWidthPlusPadding / imageWidth));
    let imageListCSS = classes.imageListMultipleColumns;
    let imageStyle = {};
    if (columnCount < 2 && windowWidthPlusPadding < imageWidth) {
        imageListCSS = classes.imageListOneColumnSmall;
        imageStyle = {
            width: windowDimensions.width,
            maxWidth: windowDimensions.width
        }
    } else {
        imageStyle = {
            width: imageWidth * columnCount,
            maxWidth: imageWidth * columnCount,
        }
    }
    return (
        <div className={classes.userKandisContainer}>
            <AddTag 
                userKandi={selectedUserKandi} 
                tag={props.tag}
                onClose={() => setSelectedUserKandi(null)}
                onSetTag={handleTagSet}
                open={selectedUserKandi ? true : false}
            />
            <Grid
                container
                direction="column"
                alignItems="center"
            >
                <Grid item className={classes.tabContainer}>
                    <Paper>
                        <Grid container direction="row" justifyContent="space-between" alignItems="center">
                            <Grid item>
                                <Tabs
                                    indicatorColor="secondary"
                                    textColor="secondary"
                                    centered
                                    value={createdOrReceivedFilter}
                                    onChange={handleCreatedOrReceivedChange}
                                >
                                    <Tab label="All" value={CreatedOrReceivedFilter.All} />
                                    <Tab label="Created" value={CreatedOrReceivedFilter.Created} />
                                    <Tab label="Received" value={CreatedOrReceivedFilter.Received} />
                                </Tabs>
                            </Grid>
                            <Grid item>
                                <Button
                                    color={JSON.stringify(filters) === JSON.stringify(defaultFilters) ? undefined : "secondary"}
                                    startIcon={<FilterListIcon />}
                                    onClick={()=>setShowFilterDialog(true)}
                                />
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
                <Grid item>
                    <ImageList rowHeight={imageWidth} style={imageStyle} className={imageListCSS} cols={columnCount}>
                        <ImageListItem key="Subheader" cols={columnCount} style={{ height: 'auto', textAlign: 'center'}}>
                            <ListSubheader component="div">
                                {renderKandiCollectionDescription()}
                            </ListSubheader>
                        </ImageListItem>
                        {userKandis.map((userKandi) => (
                            <ImageListItem key={userKandi.kandiId} cols={1}>
                                <ButtonBase
                                    onClick={()=>console.log("Image clicked for kandi " + userKandi.kandiId)}
                                    component={Link}
                                    to={`/k/${userKandi.vanityHandle}`}
                                >
                                    <img className={classes.image} src={`${publicContentUrl}kandis/${userKandi.kandiId}/0`} />
                                </ButtonBase>
                                <ImageListItemBar
                                    position="bottom"
                                    title={decodeURIComponent(userKandi.vanityHandle)}
                                    subtitle={renderKandiDescription(userKandi)}
                                    actionIcon={renderAddTagButton(userKandi)}
                                />
                            </ImageListItem>
                        ))}
                    </ImageList>
                </Grid>
            </Grid>
            <FilterDialog 
                open={showFilterDialog}
                onClose={() => setShowFilterDialog(false)}
                onApply={(newFilters: Filters) => setFilters(newFilters)}
                defaultFilters={defaultFilters}
            />
        </div>
    );
}

export default UserKandis;