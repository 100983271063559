import { useState, useEffect } from 'react';
import { ProfileProps } from './types'
import { Auth, Hub } from 'aws-amplify';
import useAsyncEffect from 'use-async-effect';
import { publicContentUrl } from '../../config/ServiceConfig';
import { getRequest, postRequest, putRequest } from '../../service/RequestAdapter';
import Paper from '@mui/material/Paper';
import { Theme, createStyles } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import Avatar from '@mui/material/Avatar';
import Badge from '@mui/material/Badge';
import Link from '@mui/material/Link';
import { Link as RouterLink } from 'react-router-dom';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import IconButton from '@mui/material/IconButton';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import SetProfilePhotoModal from '../../components/editProfile/setProfilePhotoModal';
import EditContactInfo from '../../components/editProfile/editContact';
import { NetworkUsername } from '../../components/editProfile/editContact/types';

interface MyStyles {
    profilePhotoPaper: string;
    profileAvatar: string;
    cameraAvatar: string;
    userName: string;
    socialMediaSelect: string;
}
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    profilePhotoPaper: {
        marginTop: theme.spacing(4),
        textAlign: "center",
        maxWidth: theme.breakpoints.values["md"],
        margin: "auto"
    },
    profileAvatar: {
        maxWidth: "300px",
        maxHeight: "300px",
        width: "75vw",
        height: "75vw"
    },
    cameraAvatar: {
        width: theme.spacing(5),
        height: theme.spacing(5),
        backgroundColor: "rgb(220,220,220)",
        borderColor: theme.palette.background.paper,
        borderWidth: "4px",
        borderStyle: "solid"
    },
    userName: {
        wordWrap: "break-word",
        textAlign: "center",
        [theme.breakpoints.up('md')]: {
            maxWidth: "100%",
        },
        [theme.breakpoints.down('sm')]: {
            maxWidth: "90vw",
        },
    },
    socialMediaSelect: {
        width: "100px"
    }
  }),
);

enum ProfilePhotoState {
    LoadingAuth,
    NotSignedIn,
    NotSet,
    Set
}

const Profile: React.FunctionComponent<ProfileProps> = (props) => {
    const classes = useStyles() as MyStyles;

    const [profilePhotoState, setProfilePhotoState] = useState(ProfilePhotoState.LoadingAuth);
    const [authState, setAuthState] = useState<String>("Loading");
    const [user, setUser] = useState<any | undefined>();
    const [profilePhotId, setProfilePhotoId] = useState("");
    const [showSetProfilePhotoModal, setshowSetProfilePhotoModal] = useState(false);
    const [contactInfo, setContactInfo] = useState([] as Array<NetworkUsername>);
    const [profileLoaded, setProfileLoaded] = useState(false);

    useEffect(() => {
        /** React.useState is lost after navigating to other pages, so read globally */
        Auth.currentAuthenticatedUser()
        .then(data => {
            setAuthState("SignedIn");
            setUser(data);
        })
        .catch(err => {
            setAuthState("SignedOut");
            setUser(null);
            setProfilePhotoState(ProfilePhotoState.NotSignedIn);
        });

        const authHandler = function(data: any) {
          const event = data.payload.event;
          const user = data.payload.data;
          if (event === "signOut") {
              setAuthState("SignedOut");
              setUser(null);
              setProfilePhotoState(ProfilePhotoState.NotSignedIn);
          } else if(event  === "signIn") {
              setAuthState("SignedIn");
              setUser(user);
          }
        }
        Hub.listen('auth', authHandler);

        return function cleanup() {
            Hub.remove('auth', authHandler);
        }
    }, []);

    useAsyncEffect(async isMounted => {
        if (!user) {
            return;
        }

        const profileData = await getRequest(`users/${user.username}/profile`);
        console.log("profileData = " + JSON.stringify(profileData));

        if (!isMounted()) return;
        if (profileData?.profilePhotoId) {
            setProfilePhotoId(profileData.profilePhotoId);
            setProfilePhotoState(ProfilePhotoState.Set);
        } else {
            setProfilePhotoState(ProfilePhotoState.NotSet);
        }

        if (profileData?.contactInfo) {
            setContactInfo(profileData.contactInfo);
        }

        setProfileLoaded(true);
    }, [user]);
    
    let profilePhotoComponent;
    switch (profilePhotoState) {
        case ProfilePhotoState.Set:
        case ProfilePhotoState.NotSet:
            profilePhotoComponent = (
                <Badge
                    overlap="circular"
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right"
                    }}
                    badgeContent={(
                        <Avatar className={classes.cameraAvatar}>
                            <IconButton
                                onClick={()=> setshowSetProfilePhotoModal(true)}
                            >
                                <PhotoCameraIcon/>
                            </IconButton>
                        </Avatar>
                    )}
                >
                    <Avatar
                        className={classes.profileAvatar}
                        src={`${publicContentUrl}users/${user.username}/${profilePhotId}`}
                    >
                    </Avatar>
                </Badge>
            );
            break;
        case ProfilePhotoState.LoadingAuth:
            profilePhotoComponent = <span>Loading...</span>
            break;
        case ProfilePhotoState.NotSignedIn:
            profilePhotoComponent = <span>You must first sign in to edit your profile.</span>
    }

    return (
        <Grid
            container
            direction="column"
            spacing={3}
        >
            <Grid item>
                <Paper className={classes.profilePhotoPaper}>
                    <Grid
                        container
                        direction="column"
                        alignContent="center"
                        justifyContent="center"
                        spacing={3}
                    >
                        <Grid item>
                            { profilePhotoComponent }
                        </Grid>
                        <Grid item>
                            <Typography variant="h3" className={classes.userName}>{user?.username}</Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="body2">
                                <Link component={RouterLink} to={`/u/${user?.username}`} >
                                    View public profile
                                </Link>
                            </Typography>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
            <Grid item>
                <EditContactInfo
                    username={user?.username}
                    contactInfo={contactInfo} 
                    profileLoaded={profileLoaded}
                />
            </Grid>
            <SetProfilePhotoModal 
                open={showSetProfilePhotoModal && user?.username}
                onClose={() => setshowSetProfilePhotoModal(false)}
                onSetProfilePhotoId={(photoId: string) => setProfilePhotoId(photoId)}
                username={user?.username}
            />
        </Grid>
    );
}

export default Profile;