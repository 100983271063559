export const formatEventString = function(kandiEvent: any, includeUserName: boolean) {
    switch(kandiEvent.eventType) {
        case "KANDI_CREATED": {
           return `${includeUserName ? kandiEvent.userName : ""} brought this Kandi into the world!`;
        }
        case "KANDI_NEW_OWNER": {
           return `${includeUserName ? kandiEvent.userName : ""} received the Kandi!`;
        }
        case "KANDI_LOCATION_CHECK_IN": {
            return `${includeUserName ? kandiEvent.userName : ""} says: "${kandiEvent.checkInText}"`;
        }
        default: {
            return "";
        }
   }
}
