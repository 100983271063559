import * as React from 'react';
import { ThemeSelectorProps } from "./types";
import IconButton from '@mui/material/IconButton';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';

const ThemeSelector = function ThemeSelector(props: ThemeSelectorProps) {
    return props.colorMode === "light" ? <Brightness7Icon /> : <Brightness4Icon />;
}

export default ThemeSelector;