import { useContext, useEffect, useState } from "react";
import { Auth, Hub } from 'aws-amplify';
import { NavProps } from "./types";
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import { Theme, useTheme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import { makeStyles } from '@mui/styles';
import HomeIcon from '@mui/icons-material/Home';
import PersonIcon from '@mui/icons-material/Person';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import {withRouter} from 'react-router-dom';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import IconButton from '@mui/material/IconButton';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Link from '@mui/material/Link';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { useLocation, Switch } from 'react-router-dom'
import LaunchIcon from '@mui/icons-material/Launch';
import PolicyIcon from '@mui/icons-material/Policy';
import SignInModal from '../../components/signInModal';
import Hidden from '@mui/material/Hidden';
import ThemeSelector from "../themeSelector";
import Avatar from '@mui/material/Avatar';
import AppContext from '../../context/AppContext';

export const drawerWidth = 240;
export const drawerBreakpoint = "md";
export const drawerBreakpointUp = "mdUp";
export const drawerBreakpointDown = "sm";

interface MyStyles {
  navContainer: string;
  stickToBottom: string;
  bottomMargin: string;
  drawerPaper: string;
  drawer: string;
  showWhenSmall: string;
  hideWhenSmall: string;
  drawerHeader: string;
}

const useStyles = makeStyles((theme: Theme) => 
  createStyles({
      navContainer: {
        width:'100vw',
        maxWidth:'inherit'
      },
      stickToBottom: {
        position: 'fixed',
        bottom: 0,
        width:'inherit',
        maxWidth:'inherit'
      },
      bottomMargin: {
        height: '60px',
      },
      drawerPaper: {
        width: drawerWidth,
      },
      drawer: {
        [theme.breakpoints.up(drawerBreakpoint)]: {
          width: drawerWidth,
          flexShrink: 0,
        },
      },
      showWhenSmall: {
        [theme.breakpoints.up(drawerBreakpoint)]: {
          display: 'none',
        },
      },
      hideWhenSmall: {
        [theme.breakpoints.down(drawerBreakpointDown)]: {
          display: 'none',
        },
      },
      drawerHeader: {
        alignItems: 'center',
        padding: theme.spacing(0, 1),
        justifyContent: 'flex-end',
        [theme.breakpoints.down(drawerBreakpointDown)]: {
          display: 'flex',
        },
        [theme.breakpoints.up(drawerBreakpoint)]: {
          display: 'none',
        },
      },
  })
);

const nav = function Nav(props: NavProps) {
    const theme = useTheme();
    const location = useLocation();

    const { clearFirstTimeSignin } = useContext(AppContext);
    const [authState, setAuthState] = useState<String>();
    const [mobileOpen, setMobileOpen] = useState(false);
    const [showSignInModal, setShowSignInModal] = useState(false);
    
    useEffect(() => {
        /** React.useState is lost after navigating to other pages, so read globally */
        Auth.currentAuthenticatedUser()
        .then(data => {
            setAuthState("SignedIn");
        })
        .catch(err => {
            setAuthState("SignedOut");
        });

        const authHandler = function(data: any) {
          const event = data.payload.event;
          if (event === "signOut") {
              setAuthState("SignedOut");
          } else if(event  === "signIn") {
              setAuthState("SignedIn");
          }
        }
        Hub.listen('auth', authHandler);

        return function cleanup() {
            Hub.remove('auth', authHandler);
        }
    }, []);


    const NavComponent = () => {
      const classes = useStyles() as MyStyles;

      const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
      };
  
      const navigateTo = function(path: string) {
        setMobileOpen(false);
        props.history.push(path);
      }

      const openInNewTab = (url: string) => {
        const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
        if (newWindow) newWindow.opener = null;
      };
  
      const signout = async function() {
        clearFirstTimeSignin();
        setMobileOpen(false);
        try {
          await Auth.signOut();
          navigateTo("/");
        } catch (error) {
            console.log('error signing out: ', error);
        }
      }
  
      const renderDrawer = function () {
        return (
          <div>
            <div className={classes.drawerHeader}>
              <IconButton onClick={handleDrawerToggle}>
                {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
              </IconButton>
            </div>
          <Divider />
            <List>
              <ListItem button onClick={()=>navigateTo("/")}>
                <ListItemIcon><HomeIcon /></ListItemIcon>
                <ListItemText primary="Home" />
              </ListItem>
              <ListItem button onClick={()=>navigateTo("/profile")}>
                <ListItemIcon><PersonIcon /></ListItemIcon>
                <ListItemText primary="Edit Profile" />
              </ListItem>
              <ListItem button onClick={()=>navigateTo("/contact-us")}>
                <ListItemIcon><ContactSupportIcon /></ListItemIcon>
                <ListItemText primary="Contact Us" />
              </ListItem>
              <ListItem button onClick={()=>props.onThemeChange()}>
                <ListItemIcon><ThemeSelector colorMode={props.colorMode} /></ListItemIcon>
                <ListItemText primary="Dark/Light Mode" />
              </ListItem>
              <ListItem button onClick={signout}>
                <ListItemIcon><ExitToAppIcon /></ListItemIcon>
                <ListItemText primary="Sign Out" />
              </ListItem>
              <Divider />
              <ListItem 
                button
                component={Link} 
                href="https://www.iubenda.com/privacy-policy/15440783"
                color="textPrimary"
                target="_blank"
              >
                <ListItemIcon><PolicyIcon /></ListItemIcon>
                <ListItemText primary="Privacy Policy" />
              </ListItem>
              <ListItem 
                button
                component={Link} 
                href="https://www.iubenda.com/privacy-policy/15440783/cookie-policy"
                color="textPrimary"
                target="_blank"
              >
                <ListItemIcon><PolicyIcon /></ListItemIcon>
                <ListItemText primary="Cookie Policy" />
              </ListItem>
              <ListItem 
                button
                component={Link} 
                href="https://www.iubenda.com/terms-and-conditions/15440783"
                color="textPrimary"
                target="_blank"
              >
                <ListItemIcon><PolicyIcon /></ListItemIcon>
                <ListItemText primary="Terms and Conditions" />
              </ListItem>
            </List>
          </div>
        );
      }
  
      const renderNavForLoggedInState = () => {
          return (
            <div className={classes.navContainer}>
              <BottomNavigation className={classes.stickToBottom} showLabels>
                <Hidden mdUp>
                  <BottomNavigationAction
                    icon={<MoreHorizIcon />}
                    onClick={handleDrawerToggle}
                  />
                </Hidden>
                <BottomNavigationAction icon={<Avatar src={process.env.PUBLIC_URL + '/unity_bead_icon.png'}/>} onClick={()=>openInNewTab("https://kandilove.com/collections/unity-beads")} />
                <BottomNavigationAction label="Home" icon={<HomeIcon />} onClick={()=>navigateTo("/")} />
                <BottomNavigationAction label="Profile" icon={<PersonIcon />} onClick={()=>navigateTo("/profile")} />
                <BottomNavigationAction 
                  className={classes.hideWhenSmall}
                  label="Log Out" 
                  icon={<ExitToAppIcon />}
                  onClick={signout} 
                />
              </BottomNavigation>
              <nav className={classes.drawer} aria-label="application menu">
                <Hidden mdUp implementation="css">
                  <Drawer
                    variant="temporary"
                    anchor={theme.direction === 'rtl' ? 'right' : 'left'}
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    classes={{
                      paper: classes.drawerPaper,
                    }}
                    ModalProps={{
                      keepMounted: true, // Better open performance on mobile.
                    }}
                  >
                    {renderDrawer()}
                  </Drawer>
                </Hidden>
                <Hidden smDown implementation="css">
                  <Drawer
                    classes={{
                      paper: classes.drawerPaper,
                    }}
                    variant="permanent"
                    open
                  >
                    {renderDrawer()}
                  </Drawer>
                </Hidden>
              </nav>
            </div>
          );
      }
  
      const renderNavForLoggedOutState = () => {
        if (location.pathname === "/") {
          return (
            <span></span>
          );
        } else {
          return (
            <div className={classes.navContainer}>
              <BottomNavigation className={`${classes.stickToBottom} ${classes.showWhenSmall}`} showLabels>
                <BottomNavigationAction label="Home" icon={<HomeIcon />} onClick={()=>navigateTo("/")} />
                <BottomNavigationAction
                  icon={<LaunchIcon />}
                  onClick={()=>setShowSignInModal(true)}
                  label="Sign In / Sign Up"
                />
              </BottomNavigation>
              <nav className={classes.drawer} aria-label="application menu">
                <Hidden mdUp implementation="css">
                </Hidden>
                <Hidden smDown implementation="css">
                  <Drawer
                    classes={{
                      paper: classes.drawerPaper,
                    }}
                    variant="permanent"
                    open
                  >
                    <List>
                      <ListItem button onClick={()=>navigateTo("/")}>
                        <ListItemIcon><HomeIcon /></ListItemIcon>
                        <ListItemText primary="Home" />
                      </ListItem>
                      <ListItem button onClick={()=>setShowSignInModal(true)}>
                        <ListItemIcon><LaunchIcon /></ListItemIcon>
                        <ListItemText primary="Sign In / Sign Up" />
                      </ListItem>
                    </List>
                  </Drawer>
                </Hidden>
              </nav>
              <SignInModal 
                  onClose={()=>setShowSignInModal(false)}
                  open={showSignInModal}
              />
            </div>
          )
        }
      }

      return authState === "SignedIn" ?  renderNavForLoggedInState() : renderNavForLoggedOutState();
    }

    return (
      <NavComponent />
    );
  }

  export default withRouter(nav);