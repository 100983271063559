import React, { useContext } from 'react';
import { useParams } from "react-router-dom";
import { publicContentUrl } from '../../config/ServiceConfig';
import useAsyncEffect from 'use-async-effect';
import { getRequest, postRequest } from '../../service/RequestAdapter';
import { useEffect, useState } from "react";
import { withRouter } from "react-router";
import { KandiProps } from "./types";
import SignInModal from '../../components/signInModal';
import FirstTimeSigninModal from '../../components/firstTimeSigninModal';
import OwnerHistory from '../../components/ownerHistory';
import { geoFindMe } from "../../utils/Location";
import CreateKandiCheckIn from '../../components/createKandiCheckIn';
import Fab from '@mui/material/Fab';
import Tooltip from '@mui/material/Tooltip';
import AddIcon from '@mui/icons-material/Add';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { Theme, createStyles } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import CircularProgress from '@mui/material/CircularProgress';
import Snackbar from '@mui/material/Snackbar';
import { Auth, Hub } from 'aws-amplify';
import AppContext from '../../context/AppContext';

interface MyStyles {
    fullWidth: string;
    firstPaper: string;
    ownerHistoryPaper: string;
    notification: string;
    kandiPhoto: string;
    kandiPhotoContainer: string;
    kandiTitleContainer: string;
    kandiTitle: string;
}
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fullWidth: {
        width: "100%"
    },
    firstPaper: {
        marginTop: theme.spacing(4),
        width: "100%",
    },
    ownerHistoryPaper: {
        width: "100%",
        textAlign: "center"
    },
    notification: {
        width: "290px",
        maxWidth: "80%",
        margin: "auto"
    },
    kandiPhoto: {
        borderRadius: "8px",
        border: "1px solid #ddd",
        padding: "5px",
        width: "auto",
        height: "auto",
        maxHeight: "60vh",
        [theme.breakpoints.up('md')]: {
            maxWidth: "75%",
        },
        [theme.breakpoints.down('sm')]: {
            maxWidth: "90%",
        }
    },
    kandiPhotoContainer: {
        textAlign: "center"
    },
    kandiTitleContainer: {
        justifyContent: "center"
    },
    kandiTitle: {
        wordWrap: "break-word",
        textAlign: "center",
        [theme.breakpoints.up('md')]: {
            maxWidth: "100%",
        },
        [theme.breakpoints.down('sm')]: {
            maxWidth: "90vw",
        },
    }
  }),
);

/**
 * Transitions:
 * (Customer signed in, does not own Kandi)
 * NotSet -> ReadyToClaim -> InProgress -> Claimed
 * 
 * (Customer signed in, owns Kandi)
 * NotSet -> NotAvailable
 * 
 * (Customer not signed in, does not own kandi)
 * NotSet -> NotSignedIn -> InProgress -> Claimed
 * 
 * (Customer not signed in, owns Kandi)
 * - Customers who click claim kandi, log in through modal, then show a message
 * NotSet -> NotSignedIn -> AlreadyOwned -> AlreadyOwnedMessageCleared
 */
enum KandiClaimState {
    NotSet,
    NoTagSet,
    NotAvailable, // User already owns kandi
    NotSignedIn,
    ReadyToClaim,
    InProgress,
    Claimed,
    AlreadyOwned, // user already owns kandi, but was not signed in so sees a different message than NotAvailable
    AlreadyOwnedMessageCleared
}

const Kandi: React.FunctionComponent<KandiProps> = (props) => {
    const classes = useStyles() as MyStyles;
    let { vanityHandle } = useParams() as any;
    const tagId = (props.location.state as any)?.tagId;

    /** Hooks */
    const { firstTimeSignin, clearFirstTimeSignin } = useContext(AppContext);
    const [user, setUser] = useState<any | undefined>();
    const [kandiId, setKandiId] = useState("");
    const [kandiEvents, setKandiEvents] = useState<Array<any>>([]);
    const [kandiClaimState, setKandiClaimStateInternal] = useState(KandiClaimState.NotSet);
    const [showSignInModal, setShowSignInModal] = useState(false);
    const [showCreateKandiCheckIn, setShowCreateKandiCheckIn] = React.useState(false);
    const [showSuccessClaiming, setShowSuccessClaiming] = useState(false);
    const [showFirstSigninModal, setShowFirstSigninModal] = useState(false);
    const [refreshDate, setRefreshDate] = useState(Date.now());

    const setKandiClaimState = (newState: KandiClaimState) => {
        // TODO - find better way to check for valid transitions
        if (![KandiClaimState.NoTagSet, KandiClaimState.NotAvailable, KandiClaimState.AlreadyOwned, KandiClaimState.Claimed].includes(kandiClaimState)) {
            setKandiClaimStateInternal(newState);
        }
        if (kandiClaimState === KandiClaimState.AlreadyOwned && newState === KandiClaimState.AlreadyOwnedMessageCleared) {
            setKandiClaimStateInternal(newState);
        }
    }
    useEffect(() => {
        // Disable claim kandi and location check-in if no tag was passed
        if(!tagId) {
            setKandiClaimState(KandiClaimState.NoTagSet);
        } else if (kandiEvents?.length && user) {
            if (kandiClaimState === KandiClaimState.NotSet || (kandiClaimState === KandiClaimState.NotSignedIn && user)) {
                if (user.username != kandiEvents[0].userName) {
                    setKandiClaimState(KandiClaimState.ReadyToClaim);
                } else {
                    //username matches kandi current owner
                    setKandiClaimState(KandiClaimState.NotAvailable);
                }
            }
        }
        
    }, [user, kandiEvents]);


    const claimKandiAfterSignIn = async function(user?: any) {
        if (kandiEvents?.length && (user?.username != kandiEvents[0].userName)) {
            await createKandiNewOwnerEvent();
            setShowFirstSigninModal(firstTimeSignin);
            console.log("firstTimeSignin: " + firstTimeSignin);
        } else {
            // User already owns this kandi
            setKandiClaimState(KandiClaimState.AlreadyOwned);
        }
    }

    useEffect(() => {
        /** React.useState is lost after navigating to other pages, so read globally */
        Auth.currentAuthenticatedUser()
        .then(data => {
            setUser(data);
        })
        .catch(err => {
            setUser(null);
            setKandiClaimState(KandiClaimState.NotSignedIn);
        });

        const authHandler = function(data: any) {
          const event = data.payload.event;
          const user = data.payload.data;
          if (event === "signOut") {
              setUser(null);
              setKandiClaimState(KandiClaimState.NotSignedIn);
          } else if(event  === "signIn") {
            if (showSignInModal) {
                setShowSignInModal(false);
                if (kandiClaimState == KandiClaimState.NotSignedIn) {
                    claimKandiAfterSignIn(user);
                }
            }
            
            setUser(user);
          }
        }
        Hub.listen('auth', authHandler);

        return function cleanup() {
            Hub.remove('auth', authHandler);
        }
    }, [showSignInModal, kandiClaimState, vanityHandle, firstTimeSignin]);

    useAsyncEffect(async isMounted => {
        let vanityHandleData;
        try {
            vanityHandleData = await getRequest(`vanityHandles/${vanityHandle}`);
        } catch (err) {
            console.error(`Failed to fetch vanityHandle [${vanityHandle}]`, err);
            return;
        }
        if (!vanityHandleData?.kandiId) {
            console.error(`Failed to fetch vanityHandle [${vanityHandle}]`);
            return;
        }

        let kandiEventsData;
        try {
            kandiEventsData = await getRequest(`kandis/${vanityHandleData.kandiId}/events`);
        } catch (err) {
            console.error(`Failed to fetch kandi events for kandiId [${vanityHandleData.kandiId}]`, err);
            return;
        }
        if (!kandiEventsData?.events) {
            console.error(`Failed to fetch kandi events for kandiId [${vanityHandleData.kandiId}]`);
            return;
        }

        if (!isMounted()) return;
        setKandiId(vanityHandleData.kandiId);
        setKandiEvents(kandiEventsData.events);
    }, [vanityHandle, refreshDate]);
    /*******************/


    /** Event handlers */
    const createKandiNewOwnerEvent = async function () {
        setKandiClaimState(KandiClaimState.InProgress);
        await geoFindMe(createKandiNewOwnerEventLocationCallback);
    }

    const createKandiNewOwnerEventLocationCallback = async function(loc: GeolocationPosition) {
        const locString = loc ? loc?.coords.latitude + "," + loc?.coords.longitude : ""

        const result = await postRequest(`kandis/${kandiId}/events`, {
            eventType: "KANDI_NEW_OWNER",
            location: locString,
            tagId
        });
        setKandiClaimState(KandiClaimState.Claimed);
        setRefreshDate(Date.now());
        setShowSuccessClaiming(true);
    }

    const handleFirstTimeSigninClose = function() {
        clearFirstTimeSignin();
        setShowFirstSigninModal(false);
    };

    /*******************/
    

    let kandiPhoto = null;
    if (kandiId) {
        kandiPhoto = <img className={classes.kandiPhoto} src={`${publicContentUrl}kandis/${kandiId}/0`} />;
    }

    let claimKandi = null;
    let locationCheckIn = null;
    let showLocationCheckIn =
      <Tooltip title="Add a Kandi location check-in!">
        <Fab onClick={() => setShowCreateKandiCheckIn(!showCreateKandiCheckIn)} color="primary">
          <AddIcon />
        </Fab>
      </Tooltip>;
    switch (kandiClaimState) {
        case KandiClaimState.NotSet:
            break;
        case KandiClaimState.NoTagSet:
            break;
        case KandiClaimState.NotAvailable:
            locationCheckIn = showLocationCheckIn;
            break;
        case KandiClaimState.NotSignedIn:
            claimKandi = tagId ? 
                (
                    <Button
                        color="primary" 
                        variant="contained"
                        onClick={()=>{setShowSignInModal(true)}}
                    >
                        Sign in / Sign up to Claim Kandi!
                    </Button>
                ) :
                null;
            break;
        case KandiClaimState.ReadyToClaim:
            claimKandi = (
                <Button
                    color="primary"
                    variant="contained"
                    onClick={createKandiNewOwnerEvent}
                >
                    Claim Kandi!
                </Button>
            )
            break;
        case KandiClaimState.InProgress:
            claimKandi = <CircularProgress />
            break;
        case KandiClaimState.Claimed:
            claimKandi = null;
            break;
        case KandiClaimState.AlreadyOwned:
            claimKandi = null;
            locationCheckIn = showLocationCheckIn;
            break;
    }

    return (
        <Grid
            container
            direction="column"
            spacing={2}
            alignItems="stretch"
        >
            <Grid item>
                <Paper className={classes.firstPaper}>
                    <Grid 
                        container 
                        direction="column" 
                        spacing={2} 
                        alignItems="center" 
                        justifyContent="center"
                    >
                        <Grid item className={classes.kandiPhotoContainer}>
                            { kandiPhoto }
                        </Grid>
                        <Grid item container className={classes.kandiTitleContainer}>
                            <Typography variant="h3" className={classes.kandiTitle}>
                                {decodeURIComponent(vanityHandle)}
                            </Typography>
                        </Grid>
                        <Grid item>
                            { claimKandi }
                        </Grid>
                    </Grid>
                    <SignInModal 
                        onClose={()=>setShowSignInModal(false)}
                        open={showSignInModal}
                    />
                    <FirstTimeSigninModal
                        onClose={()=> handleFirstTimeSigninClose()}
                        open={showFirstSigninModal}
                    />
                    <CreateKandiCheckIn
                        open={showCreateKandiCheckIn}
                        onClose={() => setShowCreateKandiCheckIn(false)}
                        kandiId={kandiId}
                        tagId={tagId}
                    />
                    { locationCheckIn }
                </Paper>
            </Grid>
            <Grid item>
                <Paper className={classes.ownerHistoryPaper}>
                    <Grid className={classes.fullWidth} container>
                        <Grid className={classes.fullWidth} item>
                            <OwnerHistory kandiEvents={kandiEvents} />
                        </Grid>
                    </Grid>    
                </Paper>
            </Grid>
            <Snackbar open={showSuccessClaiming} autoHideDuration={4000} onClose={()=>setShowSuccessClaiming(false)}>
                <MuiAlert onClose={()=>setShowSuccessClaiming(false)} severity="success">
                    Kandi Claimed! It will now show in your collection.
                </MuiAlert>
            </Snackbar>
            <Snackbar 
                open={kandiClaimState === KandiClaimState.AlreadyOwned} 
                autoHideDuration={4000}
                onClose={()=>setKandiClaimState(KandiClaimState.AlreadyOwnedMessageCleared)}
            >
                <MuiAlert severity="info">
                    Kandi is already yours, and will show in your collection.
                </MuiAlert>
            </Snackbar>
        </Grid>
    );
}

export default withRouter(Kandi);
