import React, { useEffect, useState } from 'react';
import './styles.css';
import { CreateKandiProps } from './types';
import { getRequest, postRequest, putRequest } from '../../service/RequestAdapter';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import PhotoIcon from '@mui/icons-material/Photo';
import Grid from '@mui/material/Grid';
import LinearProgress from '@mui/material/LinearProgress';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { geoFindMe } from "../../utils/Location";

const NOTIFICATION_TIME_OUT_IN_MS = 5000;
const VANITY_HANDLE_MAX_CHARS = 30;

enum VanityHandleState {
    NotSet,
    NotValid,
    NotAvailible,
    Availible
}

const CreateKandi: React.FunctionComponent<CreateKandiProps> = (props) => {

    const [vanityHandle, setVanityHandle] = useState("");
    const [vanityHandleState, setVanityHandleState] = useState(VanityHandleState.NotSet);
    const [vanityHandleValidationText, setVanityHandleValidationText] = useState("");
    const [kandiPhoto, setKandiPhoto] = useState(undefined as any);
    const [kandiPhotoKey, setKandiPhotoKey] = useState(Date.now());
    const [kandiPhotoLocalURL, setKandiPhotoLocalURL] = useState("");
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);
    const [showError, setShowError] = useState(false);

    const reset = function() {
        setVanityHandle("");
        setVanityHandleState(VanityHandleState.NotSet);
        setKandiPhoto(null);
        setKandiPhotoLocalURL("");
        setKandiPhotoKey(Date.now()); // Clears input field
        setIsSubmitting(false);
    }

    const resetAndClose = function () {
        reset();
        props.onClose();
    }

    const handlePhotoChanged = function(e: any) {
        const file = (e.target as any).files?.[0];
        setKandiPhoto(file);
        setKandiPhotoLocalURL(URL.createObjectURL(file))
    }

    const createKandi = async function(event: any) {
        console.log("createKandi called");
        setShowError(false);
        if (await isVanityHandleAvailable(vanityHandle) && kandiPhoto) {
            setIsSubmitting(true);
            geoFindMe(createKandiLocationCallback);
        } else {
            setVanityHandleNotAvailable();
        }
    }

    const createKandiLocationCallback = async function(loc: GeolocationPosition) {
        const kandiId = await callCreateKandi(loc);
        const setVanitySuccess = await callSetVanityHandle(kandiId);
        if (setVanitySuccess) {
            await uploadPhoto(kandiId);
            setShowSuccess(true);
            props.setLastRefreshedKandi(Date.now()); // Trigger reloading Kandi collection
            resetAndClose();
        } else {
            setIsSubmitting(false);
        }
    }

    const callCreateKandi = async function(loc: GeolocationPosition) {
        console.log("callCreateKandi called, vanityHandle=" + vanityHandle);

        const locString = loc ? loc?.coords.latitude + "," + loc?.coords.longitude : ""
        const data = await postRequest(`kandis`, { location: locString });

        console.log ("Success calling CreateKandi!");
        console.log(JSON.stringify(data));
        return data.kandiId;
    }

    const isVanityHandleAvailable = async function(handleToCheck: string): Promise<boolean> {
        console.log("checkVanityHandleAvailable called, vanityHandle=" + handleToCheck);

        try {
            const data: any = await getRequest(`vanityHandles/${encodeURIComponent(handleToCheck)}`);

            if (data?.kandiId) {
                return false;
            } else {
                return true;
            }
        } catch (err) {
            console.error(err);
            throw err;
        }
    }

    const callSetVanityHandle = async function(kandiId: string) {
        console.log("callSetVanityHandle called, vanityHandle=" + vanityHandle);
        try {
            const data = await putRequest(`vanityHandles/${encodeURIComponent(vanityHandle)}`, { kandiId });
            
            console.log ("Success setting VanityHandle!");
            console.log (data);
            return true;
        } catch (err: any) {
            if (err?.response?.data?.name === "VanityHandleTaken") {
                console.log("Vanity Handle is already in use, please pick a different handle");
                setVanityHandleNotAvailable();
            } else {
                console.error(err);
                setShowError(true);
            }
            return false;
        }
    }

    const isVanityHandleValid = function(vanityHandle: string) {
        if (vanityHandle?.length <= 3) {
            setVanityHandleState(VanityHandleState.NotValid)
            setVanityHandleValidationText("Must be 3 or more characters.");
            return false;
        } else if(vanityHandle?.length >= VANITY_HANDLE_MAX_CHARS) {
            setVanityHandleState(VanityHandleState.NotValid)
            setVanityHandleValidationText(`Must be ${VANITY_HANDLE_MAX_CHARS} or less characters.`);
            return false;
        }
        return true;
    }

    const handleVanityHandleChange = async function(updatedVanityHandle: string) {
        setVanityHandle(updatedVanityHandle);
        if (isVanityHandleValid(updatedVanityHandle)) {
            if (await isVanityHandleAvailable(updatedVanityHandle)) {
                setVanityHandleState(VanityHandleState.Availible)
                setVanityHandleValidationText("");
            } else {
                setVanityHandleNotAvailable();
            }
        }
    }

    const setVanityHandleNotAvailable = function() {
        setVanityHandleState(VanityHandleState.NotAvailible)
        setVanityHandleValidationText("Handle not availible.");
    }

    const uploadPhoto = async function(kandiId: string) {
        console.log("uploadPhoto called");
        const presignedURL = await callPhotoURL(kandiId);
        const response = await fetch(presignedURL, { method: "PUT", body: kandiPhoto });
        console.log("Photo upload response: " + JSON.stringify(response));
    }
    
    const callPhotoURL = async function(kandiId: string): Promise<string> {
        const data: any = await postRequest(`kandis/${kandiId}/photoURL`);
        console.log ("Success calling photoURL!");
        console.log (data);
        return data.presignedURL as string;
    }

    let vanityHandleAvailability;
    switch(vanityHandleState) {
        case VanityHandleState.NotSet:
            vanityHandleAvailability = <span></span>;
            break;
        case VanityHandleState.NotValid:
            vanityHandleAvailability = <span style={{color:"red"}}>Must be 3 or more characters.</span>
            break;
        case VanityHandleState.NotAvailible:
            vanityHandleAvailability = <span style={{color:"red"}}>Not availible</span>
            break;
        case VanityHandleState.Availible:
            vanityHandleAvailability = <span style={{color:"green"}}>Availible</span>
            break;
    }

    const submitEnabled = VanityHandleState.Availible === vanityHandleState && kandiPhoto && !isSubmitting;

    const progressComponent = isSubmitting ? (
        <Grid item>
            <LinearProgress />
        </Grid>
    ) : (
        null
    );

    const photoPreview = kandiPhotoLocalURL ? 
        (
            <Grid item container direction="column" alignItems="center" >
                <Grid item>
                    <img className="photoPreview" src={kandiPhotoLocalURL}/>
                </Grid>
            </Grid>
        ) : (
            <Grid item>
                <div className="photoPlaceholder">
                    <PhotoIcon style={{ fontSize: 80 }} />
                </div>
            </Grid>
        )

    return (
        <div>
            <Dialog 
                open={props.open} 
                onClose={resetAndClose} 
                aria-labelledby="form-dialog-title"
                fullWidth
                maxWidth="xs"
            >
                <DialogTitle id="form-dialog-title">Add new Kandi</DialogTitle>
                <DialogContent>
                    <Grid 
                        container
                        spacing={3}
                        direction="column"
                    >
                        <Grid item>
                            { showError && 
                                <MuiAlert onClose={()=>setShowError(false)} severity="error">
                                    Kandi creation failed. Please try again later.
                                </MuiAlert>
                            }
                        </Grid>
                        <Grid item>
                            <Button
                                variant="contained"
                                component="label"
                                color="primary"
                                key={kandiPhotoKey}
                                startIcon={<PhotoCamera/>}
                            >
                                Select Kandi Photo
                                <input
                                    type="file"
                                    accept="image/*"
                                    hidden
                                    onChange={handlePhotoChanged}
                                />
                            </Button>
                        </Grid>
                        { photoPreview }
                        <Grid item>
                            <TextField 
                                id="vanityHandle"
                                label="Give your Kandi a name"
                                error={vanityHandleValidationText ? true : false}
                                helperText={vanityHandleValidationText}
                                required
                                fullWidth
                                onChange={e => handleVanityHandleChange(e.target.value)}
                                value={vanityHandle}
                            />
                        </Grid>
                        { progressComponent }
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button color="primary" onClick={resetAndClose} disabled={isSubmitting}>
                        Cancel
                    </Button>
                    <Button color="primary" onClick={createKandi} disabled={!submitEnabled}>
                        Let's GO!
                    </Button>
                </DialogActions>
            </Dialog>
            <Snackbar open={showSuccess} autoHideDuration={3000} onClose={()=>setShowSuccess(false)}>
                <MuiAlert onClose={()=>setShowSuccess(false)} severity="success">
                    Kandi created successful! 
                </MuiAlert>
            </Snackbar>
        </div>
    );
}


export default CreateKandi;