import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { useState } from 'react';
import { FormState } from '../signIn/types';
import SignInModal from '../signInModal';
import { Theme, createStyles } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import "@fontsource/anton"

interface MyStyles {
    pageContainer: string;
    heading: string;
    button: string;
    image: string;
}
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    pageContainer: {
        paddingTop: theme.spacing(6),
        textAlign: "center"
    },
    heading: {
        fontFamily: "Anton"
    },
    button: {
        width: "200px"
    },
    image: {
        width: "300px"
    }
  })
);

const SplashScreen: React.FunctionComponent = () => {
    const classes = useStyles() as MyStyles;

    const [signInFormState, setSignInFormState] = useState<FormState | undefined>(undefined);

    return (
        <div className={classes.pageContainer}>
            <Grid
                container
                direction="column"
                spacing={3}
                alignItems="center"
            >
                <Grid item>
                    <img
                        className={classes.image}
                        src={process.env.PUBLIC_URL + '/trading_transparent.png'}
                    /> 
                </Grid>
                <Grid item>
                    <Typography variant="subtitle1">
                        Welcome to
                    </Typography>
                    <br />
                    <Typography variant="h2" className={classes.heading}>
                        Kandi Love
                    </Typography>
                </Grid>
                <Grid item>
                    <Button
                        className={classes.button}
                        color="primary"
                        variant="contained"
                        onClick={() => setSignInFormState(FormState.SignIn)}
                    >
                        Sign In
                    </Button>
                </Grid>
                <Grid item>
                    <Button
                        className={classes.button}
                        color="secondary"
                        variant="contained"
                        onClick={() => {
                            setSignInFormState(FormState.SignUp)
                        }}
                    >
                        Sign Up
                    </Button>
                </Grid>
            </Grid>
            <SignInModal
                open={signInFormState ? true : false}
                onClose={() => setSignInFormState(undefined)}
                initialState={signInFormState}
            />
        </div>
    );
}

export default SplashScreen;