import { useState, useContext } from 'react';
import { Auth } from 'aws-amplify';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import validator from 'validator';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import Container from '@mui/material/Container';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import CircularProgress from '@mui/material/CircularProgress';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { Theme, createStyles } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { SignInProps, FormState } from './types';
import AppContext from '../../context/AppContext';

interface MyStyles {
    paper: string;
    signingInProgress: string;
}
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
        marginTop: theme.spacing(8),
        padding: theme.spacing(3)
    },
    signingInProgress: {
        alignSelf: "center"
    }
  }),
);

const SignIn: React.FunctionComponent<SignInProps> = (props: SignInProps) => {
    const classes = useStyles() as MyStyles;

    const { enableFirstTimeSignin, clearFirstTimeSignin } = useContext(AppContext);
    const [formState, setFormState] = useState<FormState>(props.initialState || FormState.SignIn);
    const [username, setUsername] = useState<string>("");
    const [usernameError, setUsernameError] = useState<string>("");
    const [existingUsernames, setExistingUsernames] = useState<Array<string>>([]);
    const [password, setPassword] = useState<string>("");
    const [passwordError, setPasswordError] = useState<string>("");
    const [email, setEmail] = useState<string>("");
    const [emailError, setEmailError] = useState<string>("");
    const [verificationCode, setVerificationCode] = useState<string>("");
    const [verificationCodeError, setVerificationCodeError] = useState<string>("");
    const [successMessage, setSuccessMessage] = useState("");
    const [signingIn, setSigningIn] = useState(false);
    const [acceptPrivacyPolicy, setAcceptPrivacyPolicy] = useState(false);
    const [acceptCookiePolicy, setAcceptCookiePolicy] = useState(false);
    const [acceptTermsOfUse, setAcceptTermsOfUse] = useState(false);

    const switchToFormState = function(nextFormState: FormState) {
        setPassword("");
        setPasswordError("");
        setVerificationCode("");
        setVerificationCodeError("");
        setAndValidateUsernameForExistingAccount(username);
        switch (nextFormState) {
            case FormState.ConfirmSignUp:
                setEmail("");
                break;
        }
        setFormState(nextFormState);
    }

    /* Sign up function */
    const signUp = async function() {
        try {
            await Auth.signUp({
                username,
                password,
                attributes: {
                    email
                }
            });
            /* Once the user successfully signs up, update form state to show the confirm sign up form for MFA */
            switchToFormState(FormState.ConfirmSignUp);
        } catch (err: any) {
            if (err.code === "UsernameExistsException") {
                const updatedExistingUsernames = [...existingUsernames, username];
                setExistingUsernames(updatedExistingUsernames);
                validateUsernameForExisting(username, updatedExistingUsernames);
            } else if (err.code === "InvalidParameterException" && err.message === "Invalid email address format.") {
                setEmailError("Must be a valid email address.");
            }
            console.log({ err });
        }
    }
  
    /* Confirm sign up function for MFA */
    const confirmSignUp = async function() {
        try {
            console.log("Setting first timesign to true");
            enableFirstTimeSignin();
            await Auth.confirmSignUp(username, verificationCode);
            /* Once the user successfully confirms their account, update form state to show the sign in form*/
            setSuccessMessage("Account created successful! Sign in with your new account to continue.");
            switchToFormState(FormState.SignIn);
        } catch (err: any) {
            console.log("Setting first timesign to false");
            clearFirstTimeSignin();
            if (err.code === "CodeMismatchException") {
                setVerificationCodeError("Verification code does not match");
            }
            console.log({ err });
        }
    }
  
    /* Sign in function */
    const signIn = async function() {
        setSigningIn(true);
        try {
            const user = await Auth.signIn(username, password);
            // TODO - if user.attributes.email_verified is false then show confirm email + warning about multiple accounts
            /* Once the user successfully signs in, update the form state to show the signed in state */
            switchToFormState(FormState.Signedin);
        } catch (err: any) {
            setSigningIn(false);
            if (err?.code === "NotAuthorizedException" || err?.code === "UserNotFoundException") {
                setPasswordError("Incorrect username or password.")
            }
            console.log({ err });
        }
    }

    /* Reset password - send code */
    const sendVerificationCodeForPasswordReset = async function() {
        try {
            await Auth.forgotPassword(username);
            switchToFormState(FormState.ConfirmForgotPassword);
        } catch (err: any) {
            if (err.code === "UserNotFoundException") {
                setUsernameError("Username not found.")
            } else if (err.code === "InvalidParameterException") {
                // TODO - add ability to verify email address
                setUsernameError("Username does not have a verified email address and cannot be reset.")
            }
            console.log({ err });
        }
    }

    /* Reset password - change password */
    const resetPassword = async function() {
        try {
            await Auth.forgotPasswordSubmit(username, verificationCode, password);
            setSuccessMessage("Password reset successful! Sign in with your new password to continue.");
            switchToFormState(FormState.SignIn);
        } catch (err: any) {
            if (err.code === "CodeMismatchException") {
                setVerificationCodeError("Verification code does not match");
            }
            console.log({ err });
        }
    }

    const setAndValidateUsernameForExistingAccount = function(updatedUsername: string) {
        setUsername(updatedUsername);
        if (validateUsernameForWhitespace(updatedUsername)) {
            setUsernameError("");
        }
    }

    const setAndValidateUsernameForNewAccount = function(updatedUsername: string) {
        setUsername(updatedUsername);
        if (validateUsernameForWhitespace(updatedUsername) 
            && validateUsernameForExisting(updatedUsername, existingUsernames)) {
            setUsernameError("");
        }
    }

    const validateUsernameForWhitespace = function(updatedUsername: string) {
        if (updatedUsername.indexOf(' ') > 0) {
            setUsernameError("Username cannot contain white spaces.");
            return false;
        } else {
            return true;
        }
    }

    const validateUsernameForExisting = function(updatedUsername: string, updatedExistingUsernames: Array<String>) {
        if (updatedExistingUsernames.includes(updatedUsername)) {
            setUsernameError("Specified username already exists.");
            return false;
        } else {
            return true;
        }
    }

    const setAndValidatePassword = function(updatedPassword: string) {
        setPassword(updatedPassword);
        setPasswordError(validatePassword(updatedPassword));
    }

    const setAndClearPasswordError = function(updatedPassword: string) {
        setPassword(updatedPassword);
        setPasswordError("");
    }

    const validatePassword = function(passwordToValidate: string) {
        if (passwordToValidate.length < 8) {
            return "Must be 8 or more characters long.";
        } else if (!/[\^$\*\.\[\]\{\}\(\)?\"!\@#\%&\/\\,\>\<'\:;\|_\~\`]/g.test(passwordToValidate)) {
            // Special characters documented at:
            // https://docs.aws.amazon.com/cognito/latest/developerguide/user-pool-settings-policies.html
            return "Must contain a special character.";
        } else if (!/[0-9]/g.test(passwordToValidate)) {
            return "Must contain a number.";
        } else if (!/[A-Z]/g.test(passwordToValidate)) {
            return "Must contain an uppercase letter.";
        } else if (!/[a-z]/g.test(passwordToValidate)) {
            return "Must contain a lowercase letter.";
        } else {
            return "";
        }
    }

    const setAndValidateEmail = function(updatedEmail: string) {
        setEmail(updatedEmail);
        if (!validator.isEmail(updatedEmail)) {
            setEmailError("Must be a valid email address.");
        } else {
            setEmailError("");
        }
    }

    const handleVerificationCodeChange = function(updatedVerificationCode: string) {
        setVerificationCode(updatedVerificationCode);
        // Clear verification code error on any change
        if (verificationCodeError) {
            setVerificationCodeError("");
        }
    }

    const renderSignUp = function() {
        return (
            <Grid 
                container
                direction="column"
                spacing={3}
            >
                <Grid item>
                    <Typography variant="h5">
                        Create a new account
                    </Typography>
                </Grid>
                <Grid item>
                    <TextField
                        id="usernameInput"
                        label="Username"
                        required
                        onChange={(e) => setAndValidateUsernameForNewAccount(e.target.value)}
                        value={username}
                        error={usernameError ? true : false}
                        helperText={usernameError}
                        fullWidth
                    />
                </Grid>
                <Grid item>
                    <TextField
                        id="emailAddressInput"
                        label="Email Address"
                        type="email"
                        required
                        onChange={(e) => setAndValidateEmail(e.target.value)}
                        value={email}
                        error={emailError ? true : false}
                        helperText={emailError}
                        fullWidth
                    />
                </Grid>
                <Grid item>
                    <TextField
                        id="passwordInput"
                        label="Password"
                        type="password"
                        required
                        onChange={(e) => setAndValidatePassword(e.target.value)}
                        error={passwordError ? true : false}
                        helperText={passwordError}
                        value={password}
                        fullWidth
                    />
                </Grid>
                <Grid item>
                    <FormGroup>
                        <FormControlLabel
                            control={
                                <Checkbox 
                                    onChange={() => setAcceptPrivacyPolicy(!acceptPrivacyPolicy)}
                                    checked={acceptPrivacyPolicy} 
                                />
                            }
                            label={
                                <Typography variant="body2">
                                    I have read and accept the&nbsp;
                                    <Link
                                        href="https://www.iubenda.com/privacy-policy/15440783"
                                        title="Privacy Policy"
                                        target="_blank"
                                    >
                                        Privacy Policy
                                    </Link>
                                </Typography>
                            }
                        />
                        <FormControlLabel
                            control={
                                <Checkbox 
                                    onChange={() => setAcceptCookiePolicy(!acceptCookiePolicy)}
                                    checked={acceptCookiePolicy} 
                                />
                            }
                            label={
                                <Typography variant="body2">
                                    I have read and accept the&nbsp;
                                    <Link
                                        href="https://www.iubenda.com/privacy-policy/15440783/cookie-policy"
                                        title="Cookie Policy"
                                        target="_blank"
                                    >
                                        Cookie Policy
                                    </Link>
                                </Typography>
                            }
                        />
                        <FormControlLabel
                            control={
                                <Checkbox 
                                    onChange={() => setAcceptTermsOfUse(!acceptTermsOfUse)}
                                    checked={acceptTermsOfUse} 
                                />
                            }
                            label={
                                <Typography variant="body2">
                                    I have read and accept the&nbsp;
                                    <Link
                                        href="https://www.iubenda.com/terms-and-conditions/15440783"
                                        title="Terms and Conditions"
                                        target="_blank"
                                    >
                                        Terms and Conditions
                                    </Link>
                                </Typography>
                            }
                        />
                    </FormGroup>
                </Grid>
                <Grid item>
                    <Button
                        color="primary"
                        variant="contained"
                        fullWidth
                        onClick={signUp}
                        disabled={!(
                            username && !usernameError && password && !passwordError && email && !emailError
                            && acceptPrivacyPolicy && acceptCookiePolicy && acceptTermsOfUse
                        )}
                    >
                        Create Account!
                    </Button>
                </Grid>
                <Grid item>
                    <Divider variant="middle" />
                </Grid>
                <Grid item>
                    <Typography variant="subtitle1">
                     Already a member?
                    </Typography>
                    <Button
                        color="secondary"
                        onClick={() => switchToFormState(FormState.SignIn)}
                    >
                        Sign In
                    </Button>
                </Grid>
            </Grid>
        )
    }

    const renderConfirmSignUp = function() {
        return (
            <Grid 
                container
                direction="column"
                spacing={3}
            >
                <Grid item>
                    <Typography variant="h5">
                        Confirm your email address
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography variant="body1">
                        Check your email for a verification code and enter it below:
                    </Typography>
                </Grid>
                <Grid item>
                    <TextField
                        id="confirmationCodeInput"
                        label="Verification Code"
                        required
                        onChange={(e) => handleVerificationCodeChange(e.target.value)}
                        value={verificationCode}
                        error={verificationCodeError ? true : false}
                        helperText={verificationCodeError}
                        fullWidth
                    />
                </Grid>
                <Grid item>
                    <Button
                        color="primary"
                        variant="contained"
                        fullWidth
                        onClick={confirmSignUp}
                        disabled={!(verificationCode && !verificationCodeError)}
                    >
                        Verify!
                    </Button>
                </Grid>
            </Grid>
        );
    }

    const renderSignIn = function() {
        return (
            <Grid 
                container
                direction="column"
                spacing={3}
                alignContent="center"
                justifyContent="center"
            >
                <Grid item>
                    <Typography variant="h5">
                        Sign In
                    </Typography>
                </Grid>
                <Grid item>
                    <TextField
                        id="usernameSignInInput"
                        label="Username"
                        required
                        onChange={(e) => setAndValidateUsernameForExistingAccount(e.target.value)}
                        value={username}
                        error={usernameError ? true : false}
                        helperText={usernameError}
                        fullWidth
                    />
                </Grid>
                <Grid item>
                    <TextField
                        id="passwordSignInInput"
                        label="Password"
                        type="password"
                        required
                        onChange={(e) => setAndClearPasswordError(e.target.value)}
                        value={password}
                        error={passwordError ? true : false}
                        helperText={passwordError}
                        fullWidth
                    />
                </Grid>
                { signingIn ? 
                    (
                        <Grid item className={classes.signingInProgress}>
                            <CircularProgress />
                        </Grid>
                    ) :
                    (
                        <Grid item>
                            <Button
                                color="primary"
                                onClick={signIn}
                                variant="contained"
                                fullWidth
                                disabled={!(username && !usernameError && password)}
                            >
                                Sign In!
                            </Button>
                        </Grid>
                    )
                }
                <Grid item>
                    <Button
                        color="secondary"
                        size="small"
                        onClick={() => switchToFormState(FormState.ForgotPassword)}
                        disabled={signingIn}
                    >
                        Forgot password?
                    </Button>
                </Grid>
                <Grid item>
                    <Divider variant="middle" />
                </Grid>
                <Grid item>
                    <Typography variant="subtitle1">
                        Newbie?
                    </Typography>
                    <Button
                        color="secondary"
                        onClick={() => switchToFormState(FormState.SignUp)}
                        disabled={signingIn}
                    >
                        Create Account
                    </Button>
                </Grid>
            </Grid>
        );
    }

    const renderForgotPassword = function() {
        return (
            <Grid 
                container
                direction="column"
                spacing={3}
                alignContent="center"
                justifyContent="center"
            >
                <Grid item>
                    <Typography variant="h5">
                        Reset password
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography variant="body1">
                        Enter your username to have a verification code sent to your email address for account recovery.
                    </Typography>
                </Grid>
                <Grid item>
                    <TextField
                        id="usernameForgotPasswordInput"
                        label="Username"
                        required
                        onChange={(e) => setAndValidateUsernameForExistingAccount(e.target.value)}
                        value={username}
                        error={usernameError ? true : false}
                        helperText={usernameError}
                        fullWidth
                    />
                </Grid>
                <Grid item>
                    <Button
                        color="primary"
                        onClick={sendVerificationCodeForPasswordReset}
                        variant="contained"
                        fullWidth
                        disabled={!(username && !usernameError)}
                    >
                        Send Code
                    </Button>
                </Grid>
                <Grid item>
                    <Divider variant="middle" />
                </Grid>
                <Grid item>
                    <Button
                        color="secondary"
                        onClick={() => switchToFormState(FormState.SignIn)}
                    >
                        Cancel
                    </Button>
                </Grid>
            </Grid>
        );
    }

    const renderConfirmResetPassword = function() {
        return (
            <Grid 
                container
                direction="column"
                spacing={3}
                alignContent="center"
                justifyContent="center"
            > 
                 <Grid item>
                    <Typography variant="h5">
                        Reset password
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography variant="body1">
                        Enter the confirmation code sent to your email and your new password.
                    </Typography>
                </Grid>
                <Grid item>
                    <TextField
                        id="resetPasswordConfirmationCodeInput"
                        label="Verification Code"
                        required
                        onChange={(e) => handleVerificationCodeChange(e.target.value)}
                        value={verificationCode}
                        error={verificationCodeError ? true : false}
                        helperText={verificationCodeError}
                        fullWidth
                    />
                </Grid>
                <Grid item>
                    <TextField
                        id="passwordResetInput"
                        label="New Password"
                        type="password"
                        required
                        onChange={(e) => setAndValidatePassword(e.target.value)}
                        value={password}
                        error={passwordError ? true : false}
                        helperText={passwordError}
                        fullWidth
                    />
                </Grid>
                <Grid item>
                    <Button
                        color="primary"
                        onClick={resetPassword}
                        variant="contained"
                        fullWidth
                        disabled={!(verificationCode && !verificationCodeError && password && !passwordError)}
                    >
                        Reset password
                    </Button>
                </Grid>
                <Grid item>
                    <Divider variant="middle" />
                </Grid>
                <Grid item>
                    <Button
                        color="secondary"
                        onClick={() => switchToFormState(FormState.SignIn)}
                    >
                        Cancel
                    </Button>
                </Grid>
            </Grid>
        )
    }

    const renderForm = function() {
        switch(formState) {
            case FormState.SignUp:
                return renderSignUp();
            case FormState.ConfirmSignUp:
                return renderConfirmSignUp();
            case FormState.SignIn:
                return renderSignIn();
            case FormState.ForgotPassword:
                return renderForgotPassword();
            case FormState.ConfirmForgotPassword:
                return renderConfirmResetPassword();
        }
    }

    const renderSnackbar = function() {
        return (
            <Snackbar open={successMessage ? true : false} autoHideDuration={9000} onClose={()=>setSuccessMessage("")}>
                <MuiAlert onClose={()=>setSuccessMessage("")} severity="success">
                    { successMessage }
                </MuiAlert>
            </Snackbar>
        );
    }

    const renderInContainer = function() {
        return (
            <Container maxWidth="xs">
                <Paper className={classes.paper}>
                    { renderForm() }
                </Paper>
                { renderSnackbar() }
            </Container>
        )
    }

    const renderForModal = function() {
        return (
            <div>
                { renderForm() }
                { renderSnackbar() }
            </div>
        )
    }

    if (props.forModal) {
        return renderForModal()
    } else {
        return renderInContainer()
    }
}

export default SignIn;