import React from 'react';
import { Auth, Hub } from 'aws-amplify';
import CreateKandi from '../../components/createKandi';
import UserKandis from '../../components/userKandis';
import Typography from '@mui/material/Typography';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import { Theme, createStyles, useTheme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import Grid from '@mui/material/Grid';
import Tooltip from '@mui/material/Tooltip';
import CircularProgress from '@mui/material/CircularProgress';
import SplashScreen from '../../components/splashScreen';
import { HomeProps } from './types';

enum HomeState {
    NotSet,
    SignedIn,
    SignedOut
}

interface MyStyles {
    pageContainer: string;
}
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    pageContainer: {
        paddingTop: theme.spacing(4)
    }
  })
);

const Home: React.FunctionComponent<HomeProps> = (props: HomeProps) => {
    const classes = useStyles() as MyStyles;
    
    const [user, setUser] = React.useState<any | undefined>();
    const [showCreateKandi, setShowCreateKandi] = React.useState(false);
    const [lastRefreshedKandi, setLastRefreshedKandi] = React.useState(Date.now());
    const [homeState, setHomeState] = React.useState(HomeState.NotSet);
  
    React.useEffect(() => {
        /** React.useState is lost after navigating to other pages, so read globally */
        Auth.currentAuthenticatedUser()
        .then(data => {
            setUser(data);
            setHomeState(HomeState.SignedIn);
            props.setHideDrawerPlaceholder(false);
        })
        .catch(err => {
            console.log(err)
            setHomeState(HomeState.SignedOut);
            props.setHideDrawerPlaceholder(true);
        });

        const authHandler = function(data: any) {
            const event = data.payload.event;
            const user = data.payload.data;
            if (event === "signOut") {
                setHomeState(HomeState.SignedOut);
                props.setHideDrawerPlaceholder(true);
            } else if(event  === "signIn") {
                setUser(user);
                setHomeState(HomeState.SignedIn);
                props.setHideDrawerPlaceholder(false);
            }
        }
        Hub.listen('auth', authHandler);

        return function cleanup() {
            Hub.remove('auth', authHandler);
        }
    }, []);

    let homepage;
    switch(homeState) {
        case HomeState.NotSet:
            homepage = (
                <CircularProgress />
            );
            break;
        case HomeState.SignedOut:
            homepage = (
                <SplashScreen/>
            );
            break;
        case HomeState.SignedIn:
            homepage = (
                <div className={classes.pageContainer}>
                    <Grid container spacing={0} direction="column" alignItems="center" >
                        <Grid item>
                            <Typography variant="body1">
                                Welcome to KandiLove, {user.username}!
                            </Typography>
                        </Grid>
                        <Grid item>
                            <UserKandis userName={user.username || ''} lastUpdated={lastRefreshedKandi} />
                        </Grid>
                    </Grid>
                    <CreateKandi 
                        open={showCreateKandi} 
                        onClose={() => setShowCreateKandi(false)} 
                        setLastRefreshedKandi={setLastRefreshedKandi} 
                    />
                    <Tooltip title="Add Kandi to your collection!">
                        <Fab 
                            onClick={e => setShowCreateKandi(!showCreateKandi)}
                            color="primary"
                        >
                            <AddIcon />
                        </Fab>
                    </Tooltip>
                </div>
            )
            break;
    }

    return homepage;
};

export default Home;
