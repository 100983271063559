import React, { useState } from "react";
import { useAuthenticator } from '@aws-amplify/ui-react';
import { useParams, Redirect } from "react-router-dom";
import useAsyncEffect from 'use-async-effect';
import { AmplifyUser } from '@aws-amplify/ui';
import { Authenticator } from '@aws-amplify/ui-react';
import { Auth } from "aws-amplify";
import { getRequest } from '../../service/RequestAdapter';
import UserKandis from '../../components/userKandis';
import SignInModal from '../../components/signInModal';
import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { Theme, createStyles } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import Tooltip from '@mui/material/Tooltip';

interface MyStyles {
    infoAlert: string;
    qrCode: string;
    notSignedInMessage: string;
}
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
      infoAlert: {
          textAlign: 'left',
          width: 'fit-content',
          margin: 'auto'
      },
      qrCode: {
        textAlign: 'center',
      },
      notSignedInMessage: {
          padding: theme.spacing(2)
      }
  })
);

enum TagLookupState {
    NotSet,
    InProgress,
    KandiFound,
    KandiNotFound
}

const Tag: React.FunctionComponent = () => {
    let { uuid } = useParams() as any;
    const classes = useStyles() as MyStyles;

    const [tagLookupState, setTagLookupState] = useState(TagLookupState.NotSet);
    const [vanityHandle, setVanityHandle] = useState("");
    const [redirect, setRedirect] = useState(false);
    const [showSignInModal, setShowSignInModal] = useState(false);
    const [lastUpdated, setLastUpdated] = useState(Date.now());
    const { user, authStatus } = useAuthenticator((context) => [context.user]);

    useAsyncEffect(async isMounted => {
        setTagLookupState(TagLookupState.InProgress);
        console.log(`Checking if tag ${uuid} is associated with Kandi`);
        const data = await getRequest(`tags/${uuid}`);
        console.log("Success looking up tag " + JSON.stringify(data));
        if (!isMounted()) return;
        if (data.kandiId && data.vanityHandle) {
            setTagLookupState(TagLookupState.KandiFound);
            setVanityHandle(data.vanityHandle);
            setRedirect(true);
        } else {
            setTagLookupState(TagLookupState.KandiNotFound);
        }
    }, [uuid, lastUpdated]);

    useAsyncEffect(async isMounted => {
        let data;
        try {
            data = await Auth.currentAuthenticatedUser();
        } catch (err) {
            console.log("User is not logged in.");
            data = null;
        }
    }, []);

    const signedInExperience = function (user: AmplifyUser) {
        return (
            <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
            >
                <Grid item>
                    <Tooltip title="Attach QR code sticker to your kandi, then select the corrseponding kandi from your collection below to enable tracking it over trades.">
                        <Alert className={classes.infoAlert} severity="info">
                            <AlertTitle>
                                QR code not registered.
                            </AlertTitle>
                            Click on 'Add QR' next to the Kandi Below to register this qr code to it.
                        </Alert>
                    </Tooltip>
                </Grid>
                <Grid item>
                    <UserKandis 
                        userName={user.username || "Anonymous"}
                        lastUpdated={Date.now()}
                        tag={uuid}
                        onSetTag={()=>{setLastUpdated(Date.now())}}
                    />
                </Grid>
            </Grid>
        );
    }

    const signedOutExperience = function() {
        return  (
            <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
            >
                <Grid item className={classes.notSignedInMessage}>
                    <Typography variant="body1">
                            QR code not registered. Sign in / sign up to register the qr code.
                    </Typography>
                </Grid>
                <Grid item>
                    <Button
                        color="primary"
                        onClick={()=>{setShowSignInModal(true)}}
                        variant="contained"
                    >
                        Sign in / Sign up
                    </Button>
                </Grid>
            </Grid>
        );
    }


    const renderQRActions = function() {
        if (authStatus === "authenticated" && user) {
            return signedInExperience(user);
        } else {
            return signedOutExperience();
        }
    }

    if (redirect) {
        return <Redirect to={{
            pathname: `/k/${vanityHandle}`,
            state: { tagId: uuid }
        }} />
    } else {
        if (tagLookupState === TagLookupState.KandiNotFound) {
            return (
                <Grid
                    container
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    spacing={4}
                >
                    <Grid
                        item
                        container
                        direction="column"
                        justifyContent="center"
                        alignItems="center"
                    >
                        <Grid item>
                            <Typography variant="body1">
                                QR Code ID
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="body1">
                                <strong>{uuid}</strong>
                            </Typography>
                        </Grid>
                    </Grid>
                    <SignInModal 
                        onClose={()=>setShowSignInModal(false)}
                        open={showSignInModal}
                    />
                    <Grid item>
                        {renderQRActions()}
                    </Grid>
                </Grid>
            )
        } else {
            return null;
        }
    }
}

export default Tag;