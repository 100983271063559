import React from 'react';
import ReactDOM from 'react-dom';
import { createRoot } from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Amplify, Auth } from 'aws-amplify';
import { awsAmplifyConfig, awsDevelopmentAmplifyConfig } from './config/AmplifyConfig'


console.log("Clinton test, configuring amplify 3");

if (process.env.NODE_ENV === "development") {
  console.log("Clinton test, configuring amplify 1");
  Amplify.configure(awsDevelopmentAmplifyConfig);
} else {
  console.log("Clinton test, configuring amplify 2  process.env.REACT_APP_USER_POOL_I = " + process.env.REACT_APP_USER_POOL_ID);
  Amplify.configure(awsAmplifyConfig);
}

const root = createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
