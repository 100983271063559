import React, { useState, ReactNode } from 'react';
import AppContext from './AppContext';

interface MyProviderProps {
    children: ReactNode;
  }

const AppContextProvider = ({ children }: MyProviderProps) => {
  const [firstTimeSignin, setFirstTimeSignin] = useState(false);

  const clearFirstTimeSignin = () => {
    setFirstTimeSignin(false);
  }

  const enableFirstTimeSignin = () => {
    setFirstTimeSignin(true);
  }

  return (
    <AppContext.Provider value={{ firstTimeSignin, clearFirstTimeSignin, enableFirstTimeSignin }}>
      {children}
    </AppContext.Provider>
  );
};

export default AppContextProvider;
