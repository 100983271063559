export enum FormState {
    SignUp = 1,
    ConfirmSignUp,
    SignIn,
    ForgotPassword,
    ConfirmForgotPassword,
    Signedin
}

export interface SignInProps {
    forModal?: boolean,
    initialState?: FormState
}