import React from 'react';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { Auth } from 'aws-amplify';
import { Wrapper, Status } from "@googlemaps/react-wrapper";
import axios from 'axios';
import { geoFindMe } from '../../utils/Location';

const apiGatewayUrlParam = `https://api.${process.env.REACT_APP_DOMAIN}/service/`;

const Map: React.FunctionComponent = () => {
    const [msg, setMsg] = React.useState<string>();
    const [pos, setPos] = React.useState<GeolocationPosition>();
    const { user, authStatus } = useAuthenticator((context) => [context.user]);

    if (!pos) {
      geoFindMe(setPos);
    }

    return (
      (authStatus === "authenticated" && user) ? (
        <div id="map-page" className="App">
          <div>Hello, {user.username}</div>
          <button onClick={e => {showMap(e, pos)}}>Show map</button><br/>
          <form onSubmit={e => {checkIn(e, msg, pos) }}>
            <input name="msg" onChange={e => setMsg(e.target.value)}/>
            <input type="submit" value="Check-in Kandi!" />
          </form>
          <p id="status"></p>
          <GoogleMapsComponent />
        </div>
      ) : (
        null
      )
    )
};

async function checkIn(e: any, msg: string|undefined, pos: GeolocationPosition|undefined) {
  console.log("checkIn called with msg " + msg + " and coords " + pos?.coords.latitude + ", " + pos?.coords.longitude);
  e.preventDefault();

  if (!apiGatewayUrlParam) {
    throw new Error("apiGatewayUrlParam has not been set");
  }

  const currentSession = await Auth.currentSession();

  const jwtToken1 = currentSession.getIdToken().getJwtToken();
  axios.post( apiGatewayUrlParam + "kandis/0/events", {
    message: msg,
    latitude: pos?.coords.latitude,
    longitude: pos?.coords.longitude
  }, {
    headers: { 'Authorization' :  jwtToken1 }
  })
  .then(function(apiRes){
      console.log ("Success calling API!!");
      console.log (apiRes.data);
  })
  .catch( function(err) {
      console.log (err);
  } );
}

const render = (status: Status): any => {
  if (status === Status.LOADING) return <Spinner />;
  if (status === Status.FAILURE) return <ErrorComponent />;
  return null;
};

const GoogleMapsComponent = () => (
  <Wrapper apiKey={`${process.env.REACT_APP_MAPS_API_KEY}`} render={render}>
    <div id="map"></div>
  </Wrapper>
);

const Spinner = () => (
  <div>Sit tight, map loading!</div>
)

const ErrorComponent = () => (
  <div>Uh oh, unable to load map</div>
)

async function showMap(e:any, pos:GeolocationPosition|undefined) {
  let map = new google.maps.Map(document.getElementById("map") as HTMLElement, {
    center: { lat: 47.6131229, lng: -122.4121035 },
    zoom: 11,
  });
  if (pos) {
    new google.maps.Marker({position: {lat: pos.coords.latitude, lng: pos.coords.longitude}, map: map});
  }
}

export default Map;
