import { Component, FunctionComponent, useState, useEffect } from "react";
import { Theme, createStyles } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import SaveIcon from '@mui/icons-material/Save';
import AddIcon from '@mui/icons-material/Add';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import { SocialIcon } from 'react-social-icons';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';
import { EditContactInfoProps, Network, NetworkUsername } from "./types";
import _ from 'lodash'
import { putRequest } from '../../../service/RequestAdapter';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';

interface MyStyles {
    dividerContainer: string;
    socialMediaButtonContainer: string;
    socialMediaPaper: string;
    contactInfoPaper: string;
}
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    contactInfoPaper: {
        textAlign: "center",
        maxWidth: theme.breakpoints.values["md"],
        padding: theme.spacing(1),
        margin: "auto"
    },
    socialMediaPaper: {
        margin: theme.spacing(1),
        padding: theme.spacing(1),
        width: 'fit-content'
    },
    socialMediaButtonContainer: {
        width: '100%',
    },
    socialMediaButton: {
        justifyContent: 'flex-start'
    },
    dividerContainer: {
        width: '100%'
    }
  }),
);

enum EditContactState {
    LoadingExistingContacts,
    ReadyForEdit
}

const MAX_USERNAME_LENGTHS: Record<Network, number> = {
    [Network.Facebook]: 50,
    [Network.Instagram]: 30,
    [Network.Twitter]: 15,
}

const EditContactInfo: FunctionComponent<EditContactInfoProps> = (props: EditContactInfoProps) => {
    const classes = useStyles() as MyStyles;

    const [editContactState, setEditContactState] = useState(EditContactState.LoadingExistingContacts);
    const [existingContactInfo, setExistingContactInfo] = useState([] as Array<NetworkUsername>);
    const [contactInfo, setContactInfo] = useState([] as Array<NetworkUsername>);
    const [availableNetworks, setAvailableNetworks] = useState(Object.values(Network));
    const [addSocialAnchorEl, setAddSocialAnchorEl] = useState(null);
    const [usernameErrors, setUsernameErrors] = useState<Record<Network, string>>({
        [Network.Facebook]: "",
        [Network.Instagram]: "",
        [Network.Twitter]: "",
    });
    const [showSuccessSave, setShowSuccessSave] = useState(false);

    useEffect(() => {
        if (props.profileLoaded) {
            setExistingContactInfo(props.contactInfo);
            setContactInfo(_.cloneDeep(props.contactInfo));
            setEditContactState(EditContactState.ReadyForEdit);
            setAvailableNetworks(availableNetworks.filter(availableNetwork => {
                return props.contactInfo
                    .every(networkUsername => networkUsername.network != availableNetwork)
            }));
        }
    }, [props.contactInfo, props.profileLoaded]);

    const addSocialOpen = Boolean(addSocialAnchorEl);
    const handleAddSocialClick = (event: any) => {
      setAddSocialAnchorEl(event.currentTarget);
    };
    const handleAddSocialClose = () => {
      setAddSocialAnchorEl(null);
    };

    const handleAddNetworkClick = (network: Network) => {
        const updatedNetworks = [...contactInfo];
        updatedNetworks.push({
            network,
            userName: ""
        });

        setContactInfo(updatedNetworks);
        setAvailableNetworks(availableNetworks
            .filter(availableNetwork => availableNetwork != network));
        setAddSocialAnchorEl(null); // Closes the menu
    }

    const handleRemoveNetworkClick = (network: Network) => {
        setContactInfo(contactInfo.filter(networkUsername => networkUsername.network != network));
        setAvailableNetworks([...availableNetworks, network]);
        updateUsernameErrors(network, "");
    }

    const handleNetworkUsernameUpdate = (network: Network, updatedUsername: string) => {
        const updatedContactInfo = [...contactInfo];
        const networkUserNameToUpdate = updatedContactInfo
            .find(networkUsername => networkUsername.network === network);
        if (networkUserNameToUpdate) {
            networkUserNameToUpdate.userName = updatedUsername;
            updateUsernameErrors(network, updatedUsername);
        }
        setContactInfo(updatedContactInfo);
    }

    const updateUsernameErrors = (network: Network, updatedUsername: string) => {
        const updatedUsernameErrors = {...usernameErrors}
        if (updatedUsername.length > MAX_USERNAME_LENGTHS[network]) {
            updatedUsernameErrors[network] = "Username cannot exceed " + MAX_USERNAME_LENGTHS[network];
        } else {
            updatedUsernameErrors[network] = ""
        }
        setUsernameErrors(updatedUsernameErrors);
    }

    const handleSaveClick = async () => {
        await putRequest(`users/${props.username}/profile/contactInfo`, { contactInfo });

        setExistingContactInfo(_.cloneDeep(contactInfo));
        setShowSuccessSave(true);
    }

    const isSaveEnabled = () => {
        return editContactState === EditContactState.ReadyForEdit 
            && ! _.isEqual(existingContactInfo, contactInfo)
            && contactInfo.every(networkUsername => networkUsername.userName != "")
            && Object.values(usernameErrors).every(error => error === "")
    }

    const isAddNetworkEnabled = () => {
        return editContactState === EditContactState.ReadyForEdit 
            && availableNetworks.length > 0
    }

    const renderSocialMediaInputs = () => {
        const components = contactInfo.map((networkUsername: NetworkUsername) => {
            return (
                <Grid
                    item
                    container
                    direction="column"
                    spacing={1}
                    key={`${networkUsername.network}-container`}
                >
                    {renderSocialMediaInput(networkUsername)}
                    <Grid
                        item
                        className={classes.dividerContainer}
                        key={`${networkUsername.network}-divider`}
                    >
                        <Divider variant="middle" />
                    </Grid>
                </Grid>
            );
        });
        return (
            <Grid
                item
                container
                direction="column"
                alignItems="center"
                spacing={2}
             >
                {components}
            </Grid>
        )
    }

    const renderSocialMediaInput = (networkUsername: NetworkUsername) => {
        return (
            <Grid
                item
                className={classes.socialMediaButtonContainer}
                container
                direction="row"
                alignItems="center"
                key={networkUsername.network}
                wrap="nowrap"
            >
                <Grid item>
                    <IconButton onClick={()=>handleRemoveNetworkClick(networkUsername.network)}>
                        <DeleteRoundedIcon
                            color="secondary"
                        />
                    </IconButton>
                </Grid>
                <Grid item>
                    <Paper className={classes.socialMediaPaper} elevation={24}>
                        <Grid
                            container
                            direction="row"
                            spacing={2}
                            alignItems="center"
                            wrap="nowrap"
                        >
                            <Grid item>
                                <SocialIcon network={networkUsername.network.toLowerCase()} />
                            </Grid>
                            <Grid item>
                                <TextField
                                    label={`${networkUsername.network} username`}
                                    variant="outlined" 
                                    value={networkUsername.userName}
                                    required
                                    onChange={e => handleNetworkUsernameUpdate(networkUsername.network, e.target.value)}
                                    helperText={usernameErrors[networkUsername.network]}
                                    error={usernameErrors[networkUsername.network] ? true : false}
                                />
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
        );
    }

    const renderAddSocialMediaMenu = () => {
        const components = availableNetworks.map(availableNetwork => {
            return (
                <MenuItem 
                    onClick={()=>handleAddNetworkClick(availableNetwork)}
                    key={`${availableNetwork}-menu-item`}
                >
                    {availableNetwork}
                </MenuItem>
            );
        })

        return (
            <Menu   
                id='add-social-menu'
                anchorEl={addSocialAnchorEl}
                open={addSocialOpen}
                onClose={handleAddSocialClose}
                MenuListProps={{
                    'aria-labelledby': 'add-social-button'
                }}
            >
                {components}
            </Menu>
        );
    }

    return (
        <Paper className={classes.contactInfoPaper}>
            <Grid
                container
                direction="column"
                alignContent="flex-start"
                justifyContent="flex-start"
                spacing={3}
            >
                <Grid item>
                    <Typography variant="h5">
                        Contact Info
                    </Typography>
                </Grid>
                <Grid
                    item
                    container
                    direction="column"
                    alignItems="center"
                    spacing={2}
                >
                    {renderSocialMediaInputs()}
                    <Grid
                        item
                        container
                        direction="row"
                        alignItems="center"
                        justifyContent="flex-end"
                        spacing={2}
                    >
                        <Grid item>
                            <Button
                                id="add-social-button"
                                startIcon={<AddIcon />}
                                onClick={handleAddSocialClick}
                                color="primary"
                                variant="contained"
                                disabled={!isAddNetworkEnabled()}
                            >
                                Add Link
                            </Button>
                            {renderAddSocialMediaMenu()}
                        </Grid>
                        <Grid item>
                                <Button
                                    color="primary"
                                    variant="contained"
                                    startIcon={<SaveIcon />}
                                    disabled={!isSaveEnabled()}
                                    onClick={handleSaveClick}
                                >
                                    Save
                                </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Snackbar open={showSuccessSave} autoHideDuration={4000} onClose={()=>setShowSuccessSave(false)}>
                <MuiAlert onClose={()=>setShowSuccessSave(false)} severity="success">
                    Contact info has been updated! It is now visible on your public profile.
                </MuiAlert>
            </Snackbar>
        </Paper>
    );
}

export default EditContactInfo;