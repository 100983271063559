import React, { Component, useState } from 'react'
import { QrReader } from 'react-qr-reader'
import './styles.scss'
import { QRScannerProps } from './types'

const QRScanner: React.FunctionComponent<QRScannerProps> = (props) => {
    const [result, setResult] = useState("");

    const handleScan = (data: any) => {
        if (data) {
            setResult(data);
            props.onScan(data);
        }
    }
    
    const handleError = (err: any) => {
        console.error(err)
    }

    const onResult = (result: any, error: any) => {
      if (!!result) {
        handleScan(result);
      }

      if (!!error) {
        handleError(error);
      }
    };

    return (
        <div>
          <QrReader
            scanDelay={300}
            onResult={onResult}
            constraints={{ facingMode: 'environment' }}
            className="qr-scanner-fullscreen"
          />
          <p>{result}</p>
        </div>
      )
}

export default QRScanner