import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { FiltersDialogProps, Filters } from './types';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { useState } from 'react';

export enum CurrentlyOwnedFilter {
    Both = "Both",
    Current = "Current",
    Former = "Former"
}

const FiltersDialog: React.FunctionComponent<FiltersDialogProps> = (props: FiltersDialogProps) => {

    const [currentlyOwnedFilter, setCurrentlyOwnedFilter] = useState(props.defaultFilters.currentlyOwnedFilter);

    const handleCurrentlyOrPreviouslyOwnedChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCurrentlyOwnedFilter((event.target as HTMLInputElement).value as CurrentlyOwnedFilter);
    };

    const handleApply = () => {
        props.onApply({
            currentlyOwnedFilter
        });
        props.onClose();
    }

    const handleClear = () => {
        setCurrentlyOwnedFilter(props.defaultFilters.currentlyOwnedFilter);
        props.onApply(props.defaultFilters);
        props.onClose();
    }

    return (
        <Dialog
            open={props.open}
            onClose={props.onClose}
            aria-labelledby="kandi-filters-title"
        >
            <DialogTitle id="kandi-filters-title">
                Filter your Kandi Collection
            </DialogTitle>
            <DialogContent>
                <FormControl component="fieldset">
                    <FormLabel component="legend">Current/Previously Owned</FormLabel>
                    <RadioGroup 
                        aria-label="current-previous-owned" 
                        name="current-previous-owned" 
                        value={currentlyOwnedFilter} 
                        onChange={handleCurrentlyOrPreviouslyOwnedChange}
                    >
                        <FormControlLabel value={CurrentlyOwnedFilter.Both} control={<Radio />} label="Both" />
                        <FormControlLabel value={CurrentlyOwnedFilter.Current} control={<Radio />} label="Currently Own (default)" />
                        <FormControlLabel value={CurrentlyOwnedFilter.Former} control={<Radio />} label="Previously Owned" />
                    </RadioGroup>
                </FormControl>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.onClose} color="primary">
                    Cancel
                </Button>
                <Button onClick={handleClear} color="primary">
                    Clear
                </Button>
                <Button onClick={handleApply} color="primary">
                    Apply
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default FiltersDialog;