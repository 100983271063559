import React, { useState } from 'react';
import './styles.css';
import { CreateKandiCheckInProps } from './types';
import { postRequest } from '../../service/RequestAdapter';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import LinearProgress from '@mui/material/LinearProgress';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { geoFindMe } from "../../utils/Location";

const CreateKandiCheckIn: React.FunctionComponent<CreateKandiCheckInProps> = (props) => {

    const [checkInText, setCheckInText] = useState("");
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);

    const reset = function() {
        setCheckInText("");
        setIsSubmitting(false);
    }

    const resetAndClose = function () {
        reset();
        props.onClose();
    }

    const checkInKandi = async function(event: any) {
        console.log("checkInKandi called");
        setIsSubmitting(true);
        geoFindMe(checkInKandiLocationCallback);
    }

    const checkInKandiLocationCallback = async function(loc: GeolocationPosition) {
        const eventId = await callCheckInKandi(loc);
        setShowSuccess(true);
        resetAndClose();
    }

    const callCheckInKandi = async function(loc: GeolocationPosition) {
        console.log("callCheckInKandi called");

        const locString = loc ? loc?.coords.latitude + "," + loc?.coords.longitude : ""
        const result = await postRequest(`kandis/${props.kandiId}/events`, {
            eventType: "KANDI_LOCATION_CHECK_IN",
            checkInText: checkInText,
            location: locString,
            tagId: props.tagId
        });

        console.log ("Success calling callCheckInKandi!");
        console.log(JSON.stringify(result));
        return result.eventId;
    }

    const submitEnabled = !isSubmitting;

    const progressComponent = isSubmitting ? (
        <Grid item>
            <LinearProgress />
        </Grid>
    ) : (
        null
    );

    return (
        <div>
            <Dialog
                open={props.open}
                onClose={resetAndClose}
                aria-labelledby="form-dialog-title"
                fullWidth
                maxWidth="xs"
            >
                <DialogTitle id="form-dialog-title">Add new memory to this Kandi!</DialogTitle>
                <DialogContent>
                    <Grid
                        container
                        spacing={3}
                        direction="column"
                    >
                        <Grid item>
                            <TextField
                                id="checkInText"
                                label="What's going on? Who you with?"
                                fullWidth
                                value={checkInText}
                                onChange={e => setCheckInText(e.target.value)}
                            />
                        </Grid>
                        { progressComponent }
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button color="primary" onClick={resetAndClose} disabled={isSubmitting}>
                        Cancel
                    </Button>
                    <Button color="primary" onClick={checkInKandi} disabled={!submitEnabled}>
                        Let's GO!
                    </Button>
                </DialogActions>
            </Dialog>
            <Snackbar open={showSuccess} autoHideDuration={3000} onClose={()=>setShowSuccess(false)}>
                <MuiAlert onClose={()=>setShowSuccess(false)} severity="success">
                    Kandi check-in succeeded!
                </MuiAlert>
            </Snackbar>
        </div>
    );
}

export default CreateKandiCheckIn;
